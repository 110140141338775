import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogConfig, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_NOTIFIER_OPTIONS } from '@constants/index';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '@shared';
import { NotifierModule } from 'angular-notifier';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { environment } from '@env';
import { NzSpinModule } from 'ng-zorro-antd/spin';

registerLocaleData(en);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    NotifierModule.withConfig(APP_NOTIFIER_OPTIONS),
    MatDialogModule,
    SharedModule,
    FormsModule,
    NzSpinModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: false,
        height: 'auto',
        maxHeight: 'calc(100vh - 100px)',
        minHeight: '150px',
        width: '45%',
        maxWidth: 'calc(100vw - 100px)',
        minWidth: '300px',
        autoFocus: true,
        hasBackdrop: true
      } as MatDialogConfig
    },
    {
      provide: 'BADA_ENDPOINT',
      useValue: environment.BADA_ENDPOINT,
      multi: true
    },
    {
      provide: 'AFFORTABLE_ENDPOINT',
      useValue: environment.AFFORTABLE_ENDPOINT,
      multi: true,
    },
    {
      provide: 'ARTNET_ENDPOINT',
      useValue: environment.ARTNET_ENDPOINT,
      multi: true
    },
    {
      provide: 'LAPADA_ENDPOINT',
      useValue: environment.LAPADA_ENDPOINT,
      multi: true
    },
    {
      provide: 'FIRST_DIBS_ENDPOINT',
      useValue: environment.FIRST_DIBS_ENDPOINT,
      multi: true
    },
    {
      provide: 'ARTSY_ENDPOINT',
      useValue: environment.ARTSY_ENDPOINT,
      multi: true
    },
    {
      provide: 'COVET_ENDPOINT',
      useValue: environment.COVET_ENDPOINT,
      multi: true
    },
    {
      provide: 'SELLING_ENDPOINT',
      useValue: environment.SELLING_ENDPOINT,
      multi: true
    },
    {
      provide: 'INTEGRATION_ENDPOINT',
      useValue: environment.INTEGRATION_ENDPOINT,
      multi: true
    },
    {
      provide: 'ETSY_ENDPOINT',
      useValue: environment.ETSY_ENDPOINT,
      multi: true
    },
    {
      provide: 'TWITTER_ENDPOINT',
      useValue: environment.TWITTER_ENDPOINT,
      multi: true
    },
    // {
    //   provide: 'MARKETPLACE_ENDPOINT',
    //   useValue: environment.MARKETPLACE_ENDPOINT,
    //   multi: true
    // },
    { provide: NZ_I18N, useValue: en_US }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
