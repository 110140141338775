import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { IAffortableCreateRequest, IArtistList, IMediumAndMaterial, IMediumAndMaterialResponse } from '@models/index';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AffortableService {

    constructor(
        private _httpClient: HttpClient,
        @Inject('AFFORTABLE_ENDPOINT') private _endpoint: string
    ) { }

    public getMediumsAndMaterials(data: IMediumAndMaterial, cId: string, token: string) {
      let headers = new HttpHeaders();
      headers = headers.append('c-id', cId);
      headers = headers.append('token', token);
      return this._httpClient.post<IMediumAndMaterialResponse>
      (`${environment.API_URL}/integration-get-other-data`, data, {headers});
    }

    public getArtists(key: string, cId: string, token: string) {
      let headers = new HttpHeaders();
      headers = headers.append('c-id', cId);
      headers = headers.append('token', token);
      return this._httpClient.get<IArtistList>(`${environment.API_URL}/integration-get-artists/${key}`, { headers });
    }

    public add(data: IAffortableCreateRequest, token: string, cId: string): Observable<void> {
        let headers = new HttpHeaders();
        headers = headers.append('token', token);
        headers = headers.append('c-id', cId);
        return this._httpClient.post<void>(`${environment.API_URL}/integration-add`, data, {headers});
    }
}
