import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultModalInputData, ModalOutputData } from '@models/index';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit {

  constructor(
    public modalRef: MatDialogRef<ModalComponent, ModalOutputData>,
    @Inject(MAT_DIALOG_DATA) public data: DefaultModalInputData
  ) { }

  ngOnInit() {
    console.log(this.data, 'data');
  }

  /**
   * close dialog and continue
   *
   * @param null
   *
   * @return `void`
   */
  onContinue() {
    this.modalRef.close({ continue: true, outputData: null });
  }
}
