export const BADA_CATEGORIES = [
    {
        value: '357',
        label: 'Ancient & Ethnographical Items'
    },
    {
        value: '21',
        label: 'Architectural items and statuary'
    },
    {
        value: '86',
        label: 'Arms, armour & walking canes'
    },
    {
        value: '358',
        label: 'Asian & Oriental Art'
    },
    {
        value: '360',
        label: 'Books, maps & manuscripts'
    },
    {
        value: '359',
        label: 'Carpets & Textiles'
    },
    {
        value: '361',
        label: 'Ceramics'
    },
    {
        value: '150',
        label: 'Clocks, watches & scientific instruments'
    },
    {
        value: '364',
        label: 'Collectables & Musical Instruments'
    },
    {
        value: '186',
        label: 'Decorative arts'
    },
    {
        value: '362',
        label: 'Furniture'
    },
    {
        value: '144',
        label: 'Glass'
    },
    {
        value: '365',
        label: 'Jewellery'
    },
    {
        value: '10513',
        label: 'Lighting'
    },
    {
        value: '215',
        label: 'Metalware'
    },
    {
        value: '10514',
        label: 'Mirrors'
    },
    {
        value: '363',
        label: 'Paintings, Drawings & Watercolours'
    },
    {
        value: '250',
        label: 'Prints, Posters & Photography'
    },
    {
        value: '257',
        label: 'Sculpture'
    },
    {
        value: '10480',
        label: 'Silver'
    },
    {
        value: '10481',
        label: 'Treen and bygones'
    }
];
