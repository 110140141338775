import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IImageUploadResponse, IImageDeleteRequest } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private _httpClient: HttpClient) { }

  public uploadImage(image: FormData, cId: string) {
    const headers = new HttpHeaders().set('c-id', cId);
    return this._httpClient.post<IImageUploadResponse>('https://dev01.seekunique.co/api/froala-image-upload', image, { headers });
  }

  public uploadImage2(image: FormData) {
    return this._httpClient.post<IImageUploadResponse>('https://dev01.seekunique.co/api/froala-image-upload', image);
  }

  public deleteImage(data: IImageDeleteRequest) {
    return this._httpClient.post<void>('delete-image', data);
  }
}
