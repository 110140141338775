export const AFFORTABLE_MATERIAL: string[] = [
    "Acrylic",
    "Aluminium",
    "Board",
    "Brass",
    "Canvas",
    "Enamel",
    "Fabric",
    "Glass",
    "Linen",
    "Metal",
    "N/A",
    "Paper",
    "Resin",
    "Wood"
]