export const COVET_STYLES = [
  {
    "title": "Abstract",
    "slug": "abstract"
  },
  {
    "title": "Abstract Expressionist",
    "slug": "abstract-expressionist"
  },
  {
    "title": "Abstract Geometric",
    "slug": "abstract-geometric"
  },
  {
    "title": "Academic",
    "slug": "academic"
  },
  {
    "title": "Adirondack",
    "slug": "adirondack"
  },
  {
    "title": "Aesthetic Movement",
    "slug": "aesthetic-movement"
  },
  {
    "title": "American Classical",
    "slug": "american-classical"
  },
  {
    "title": "American Modern",
    "slug": "american-modern"
  },
  {
    "title": "American Realist",
    "slug": "american-realist"
  },
  {
    "title": "Anglo-Indian",
    "slug": "anglo-indian"
  },
  {
    "title": "Art Deco",
    "slug": "art-deco"
  },
  {
    "title": "Art Nouveau",
    "slug": "art-nouveau"
  },
  {
    "title": "Artisan",
    "slug": "artisan"
  },
  {
    "title": "Arts & Crafts",
    "slug": "arts-crafts"
  },
  {
    "title": "Assemblage",
    "slug": "assemblage"
  },
  {
    "title": "Barbizon School",
    "slug": "barbizon-school"
  },
  {
    "title": "Baroque",
    "slug": "baroque"
  },
  {
    "title": "Baroque Revival",
    "slug": "baroque-revival"
  },
  {
    "title": "Bauhaus",
    "slug": "bauhaus"
  },
  {
    "title": "Belle Epoque",
    "slug": "belle-epoque"
  },
  {
    "title": "Biedermeier",
    "slug": "biedermeier"
  },
  {
    "title": "Black Forest",
    "slug": "black-forest"
  },
  {
    "title": "British Colonial",
    "slug": "british-colonial"
  },
  {
    "title": "Brutalist",
    "slug": "brutalist"
  },
  {
    "title": "Campaign Furniture",
    "slug": "campaign-furniture"
  },
  {
    "title": "Charles I",
    "slug": "charles-i"
  },
  {
    "title": "Charles II",
    "slug": "charles-ii"
  },
  {
    "title": "Charles X",
    "slug": "charles-x"
  },
  {
    "title": "Chinese Export",
    "slug": "chinese-export"
  },
  {
    "title": "Chinoiserie",
    "slug": "chinoiserie"
  },
  {
    "title": "Chippendale",
    "slug": "chippendale"
  },
  {
    "title": "Color-Field",
    "slug": "colorfield"
  },
  {
    "title": "Contemporary",
    "slug": "contemporary"
  },
  {
    "title": "Cubist",
    "slug": "cubist"
  },
  {
    "title": "Dada",
    "slug": "dada"
  },
  {
    "title": "Edo Period",
    "slug": "edo-period"
  },
  {
    "title": "Edwardian",
    "slug": "edwardian"
  },
  {
    "title": "Egyptian Revival",
    "slug": "egyptian-revival"
  },
  {
    "title": "Empire",
    "slug": "empire"
  },
  {
    "title": "Expressionist",
    "slug": "expressionist"
  },
  {
    "title": "Fauvist",
    "slug": "fauvist"
  },
  {
    "title": "Federal",
    "slug": "federal"
  },
  {
    "title": "Folk Art",
    "slug": "folk-art"
  },
  {
    "title": "French Provincial",
    "slug": "french-provincial"
  },
  {
    "title": "George I",
    "slug": "george-i"
  },
  {
    "title": "George II",
    "slug": "george-ii"
  },
  {
    "title": "George III",
    "slug": "george-iii"
  },
  {
    "title": "Georgian",
    "slug": "georgian"
  },
  {
    "title": "Gothic",
    "slug": "gothic"
  },
  {
    "title": "Gothic Revival",
    "slug": "gothic-revival"
  },
  {
    "title": "Grand Tour",
    "slug": "grand-tour"
  },
  {
    "title": "Gustavian",
    "slug": "gustavian"
  },
  {
    "title": "Hepplewhite",
    "slug": "hepplewhite"
  },
  {
    "title": "Hollywood Regency",
    "slug": "hollywood-regency"
  },
  {
    "title": "Hudson River School",
    "slug": "hudson-river-school"
  },
  {
    "title": "Impressionist",
    "slug": "impressionist"
  },
  {
    "title": "Industrial",
    "slug": "industrial"
  },
  {
    "title": "Islamic",
    "slug": "islamic"
  },
  {
    "title": "Jacobean",
    "slug": "jacobean"
  },
  {
    "title": "Japonisme",
    "slug": "japonisme"
  },
  {
    "title": "Jugendstil",
    "slug": "jugendstil"
  },
  {
    "title": "Louis XIII",
    "slug": "louis-xiii"
  },
  {
    "title": "Louis XIV",
    "slug": "louis-xiv"
  },
  {
    "title": "Louis XV",
    "slug": "louis-xv"
  },
  {
    "title": "Louis XVI",
    "slug": "louis-xvi"
  },
  {
    "title": "Medieval",
    "slug": "medieval"
  },
  {
    "title": "Meiji Period",
    "slug": "meiji-period"
  },
  {
    "title": "Mid-Century Modern",
    "slug": "midcentury-modern"
  },
  {
    "title": "Ming",
    "slug": "ming"
  },
  {
    "title": "Minimalist",
    "slug": "minimalist"
  },
  {
    "title": "Modern",
    "slug": "modern"
  },
  {
    "title": "Modernist",
    "slug": "modernist"
  },
  {
    "title": "Moorish",
    "slug": "moorish"
  },
  {
    "title": "Napoleon III",
    "slug": "napoleon-iii"
  },
  {
    "title": "Native American",
    "slug": "native-american"
  },
  {
    "title": "Navajo",
    "slug": "navajo"
  },
  {
    "title": "Neoclassical",
    "slug": "neoclassical"
  },
  {
    "title": "Neoclassical Revival",
    "slug": "neoclassical-revival"
  },
  {
    "title": "Neo-Expressionist",
    "slug": "neoexpressionist"
  },
  {
    "title": "Old Masters",
    "slug": "old-masters"
  },
  {
    "title": "Op Art",
    "slug": "op-art"
  },
  {
    "title": "Organic Modern",
    "slug": "organic-modern"
  },
  {
    "title": "Orientalist",
    "slug": "orientalist"
  },
  {
    "title": "Outsider Art",
    "slug": "outsider-art"
  },
  {
    "title": "Photorealist",
    "slug": "photorealist"
  },
  {
    "title": "Pop Art",
    "slug": "pop-art"
  },
  {
    "title": "Post-War",
    "slug": "postwar"
  },
  {
    "title": "Primitive",
    "slug": "primitive"
  },
  {
    "title": "Qing",
    "slug": "qing"
  },
  {
    "title": "Queen Anne",
    "slug": "queen-anne"
  },
  {
    "title": "Rancho Monterey",
    "slug": "rancho-monterey"
  },
  {
    "title": "Realist",
    "slug": "realist"
  },
  {
    "title": "Regency",
    "slug": "regency"
  },
  {
    "title": "Renaissance",
    "slug": "renaissance"
  },
  {
    "title": "Renaissance Revival",
    "slug": "renaissance-revival"
  },
  {
    "title": "Retro",
    "slug": "retro"
  },
  {
    "title": "Revival",
    "slug": "revival"
  },
  {
    "title": "Rococo",
    "slug": "rococo"
  },
  {
    "title": "Romantic",
    "slug": "romantic"
  },
  {
    "title": "Rustic",
    "slug": "rustic"
  },
  {
    "title": "Scandinavian Modern",
    "slug": "scandinavian-modern"
  },
  {
    "title": "Sevres",
    "slug": "sevres"
  },
  {
    "title": "Space Age",
    "slug": "space-age"
  },
  {
    "title": "Spanish Colonial",
    "slug": "spanish-colonial"
  },
  {
    "title": "Staffordshire Pottery",
    "slug": "staffordshire-pottery"
  },
  {
    "title": "Street Art",
    "slug": "street-art"
  },
  {
    "title": "Surrealist",
    "slug": "surrealist"
  },
  {
    "title": "Tribal",
    "slug": "tribal"
  },
  {
    "title": "Victorian",
    "slug": "victorian"
  },
  {
    "title": "Vienna Secession",
    "slug": "vienna-secession"
  },
  {
    "title": "William IV",
    "slug": "william-iv"
  },
  {
    "title": "Young British Artists (YBA)",
    "slug": "young-british-artists-yba"
  },
  {
    "title": "William and Mary",
    "slug": "william-and-mary"
  },
  {
    "title": "George V",
    "slug": "george-v"
  },
  {
    "title": "Pre-Raphaelite",
    "slug": "preraphaelite"
  },
  {
    "title": "Symbolist",
    "slug": "symbolist"
  },
  {
    "title": "Aesthetic",
    "slug": "aesthetic"
  },
  {
    "title": "Elizabethan",
    "slug": "elizabethan"
  },
  {
    "title": "Tudor",
    "slug": "tudor"
  },
  {
    "title": "Elizabethan II",
    "slug": "elizabethan-ii"
  },
  {
    "title": "George IV",
    "slug": "george-iv"
  },
  {
    "title": "Figurative",
    "slug": "figurative"
  },
  {
    "title": "Modern British",
    "slug": "modern-british"
  }
];
