export const STYLES = [
  'Adam Style', 'Adirondack', 'Aesthetic Movement', 'Agra', 'American Classical', 'American Colonial',
  'American Craftsman', 'American Empire', 'Anglo Raj', 'Anglo-Indian', 'Anglo-Japanese', 'Archaistic',
  'Art Deco', 'Art Nouveau', 'Arts and Crafts', 'Aubusson', 'Bakshaish', 'Balkan', 'Baltic',
  'Barbizon School', 'Baroque', 'Baroque Revival', 'Bauhaus', 'Beaux Arts', 'Belle Époque', 'Bessarabian',
  'Biedermeier', 'Black Forest', 'Bohemian', 'British Colonial', 'Brutalist', 'Campaign', 'Charles II',
  'Charles X', 'Chesterfield', 'Chinese Chippendale', 'Chinese Export', 'Chinoiserie', 'Chippendale',
  'Classical Greek', 'Classical Roman', 'Colonial Revival', 'Country', 'De Stijl', 'Directoire',
  'Dutch Colonial', 'Early Victorian', 'Eastlake', 'Edo', 'Edwardian', 'Egyptian', 'Egyptian Revival',
  'Elizabethan', 'Empire', 'Empire Revival', 'Expressionist', 'Federal', 'Folk Art', 'French Provincial',
  'Futurist', 'George I', 'George II', 'George III', 'George IV', 'Georgian', 'Gothic', 'Gothic Revival',
  'Grand Tour', 'Greco Roman', 'Greek Revival', 'Gustavian', 'Han', 'Hellenistic', 'Hepplewhite',
  'Heriz Serapi', 'High Victorian', 'Hollywood Regency', 'Industrial', 'International Style', 'Islamic',
  'Jacobean', 'James II', 'Japonisme', 'Jugendstil', 'Karl Johan', 'Kashan', 'Kazak', 'Khorassan', 'Khotan',
  'Kilim', 'Kinetic', 'Kirman', 'Late Victorian', 'Louis Philippe', 'Louis XIII', 'Louis XIV', 'Louis XV',
  'Louis XVI', 'Machine Age', 'Malayer', 'Medieval', 'Meiji', 'Mid-Century Modern', 'Ming', 'Minimalist',
  'Mission', 'Modern', 'Moorish', 'Napoleon III', 'Native American', 'Navajo', 'Neoclassical',
  'Neoclassical Revival', 'Organic Modern', 'Other', 'Oushak', 'Palladian', 'Post-Modern', 'Prairie School',
  'Pre-Columbian', 'Pre-Raphaelite', 'Prehistoric', 'Primitive', 'Qing', 'Queen Anne', 'Rancho Monterey',
  'Régence', 'Regency', 'Regency Revival', 'Renaissance', 'Renaissance Revival', 'Restauration', 'Revival',
  'Rococo', 'Rococo Revival', 'Romantic', 'Rustic', 'Sarouk Farahan', 'Scandinavian Modern', 'Schoolhouse',
  'Serapi', 'Shaker', 'Sheraton', 'Showa', 'Space Age', 'Spanish Colonial', 'Sporting Art', 'Steampunk',
  'Streamlined Moderne', 'Sultanabad', 'Sumak', 'Suzani', 'Tabriz', 'Taisho', 'Tang', 'Tibetan', 'Tribal',
  'Tudor', 'Tulu', 'Victorian', 'Vienna Secession', 'William and Mary', 'William IV'
];
