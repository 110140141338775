import { ISellingAddRequest } from '@models/index';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class SellingService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('SELLING_ENDPOINT') private _endpoint: string,
  ){
  }

  public processSellingForm(data: ISellingAddRequest, token: string, cId: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('c-id', cId);
    return this._httpClient.post<void>(`${environment.API_URL}/integration-add`, data, {headers});
  }

  public get(data: { key: string, stock_number: string }, cId: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.post(`${environment.API_URL}/integration-get-stock`, data, { headers });
  }

  public edit(data: ISellingAddRequest, cId: string, token: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.put<void>(`${environment.API_URL}/integration-update/${data.stock_number}`, data, { headers });
  }

}
