import { Language, Theme } from '@enums/index';

export const APP_LANGUAGES: Language[] = [
  Language.ENGLISH,
  Language.SPANISH,
];

export const APP_DEFAULT_LANG: Language = Language.ENGLISH;

export const APP_THEMES: Theme[] = [
  Theme.LIGHT
];
export const APP_DEFAULT_THEME: Theme = Theme.LIGHT;

export const APP_DATE_FORMATS: string[] = [
  'dd-MMM-yyyy',
  'MMM dd, yyyy',
  'MM/dd/yyyy',
  'yyyy-MM-dd'
];
export const APP_DEFAULT_DATE_FORMAT: string = APP_DATE_FORMATS[0];

export const APP_TIME_FORMATS: string[] = ['24hour', '12hour'];
export const APP_DEFAULT_TIME_FORMAT: string = APP_TIME_FORMATS[1];
export const DEFAULT_DELAY: number = 500;
