import { Injectable } from '@angular/core';
import { Loading } from '@enums/index';
import { LoadingItem } from '@models/index';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loadingId: number = 0;

  private _loadingItems: LoadingItem[] = [];

  // get the private property (_loadingItems) value
  get loadingItemsList() {
    return this._loadingItems;
  }

  // set the private property (_loadingItems) value
  // and emit event for listeners
  set loadingItems(loadingItems: LoadingItem[]) {
    this._loadingItems = loadingItems || [];
    this.loadingChange.next(this._loadingItems);
  }

  loadingChange: BehaviorSubject<LoadingItem[]> = new BehaviorSubject(
    this.loadingItemsList
  );

  constructor() { }

  global(name?: string) {
    return this._turnOn(Loading.GLOBAL, name);
  }

  local(name?: string) {
    return this._turnOn(Loading.LOCAL, name);
  }

  /**
   * function for turn on loading
   *
   * @param loading is the sstate of the loading item
   * @param name is the loading item name
   * in case of you need to add it from outside
   *
   * @return `string`
   * return the unique name of the current turned on loading item
   */
  private _turnOn(loading: Loading, name?: string): string {
    // get unique id for the notifier
    const loadingName = name || `loading-${++this._loadingId}`;

    const loadingItem = { loading, name: loadingName } as LoadingItem;

    this.loadingItems = [loadingItem, ...this.loadingItemsList];

    return loadingName;
  }

  /**
   * function for turn off loading
   *
   * @param loadingItem
   *
   * @return `void`
   */
  turnOff(name: string) {
    this.loadingItems = this.loadingItemsList.filter(item => {
      return name !== item.name;
    });
  }

  /**
   * the function emits event
   * only when into `loadingItems` has item
   * which should turn `global` loading
   *
   * @param null
   *
   * @return `Observable<boolean>`
   */
  listenGlogalLoading() {
    return this._listenLoadingByType(Loading.GLOBAL);
  }

  /**
   * the function emits event
   * only when into `loadingItems` has item
   * which should turn `local` loading
   *
   * @param null
   *
   * @return `Observable<boolean>`
   */
  listenLocalLoading() {
    return this._listenLoadingByType(Loading.LOCAL);
  }

  /**
   * check all of the loading items by loading type
   * and return `Observable<boolean>`
   *
   * @param loading the loading type by which should be checked
   *
   * @return `Observable<boolean>`
   */
  private _listenLoadingByType(loading: Loading) {
    return this.loadingChange.asObservable().pipe(
      debounceTime(10),
      filter((loadingItems) => {
        return loadingItems?.length === 0 ||
          !!loadingItems?.find(item => item.loading === loading);
      }),
      map(loadingItems => {
        const canContinue = !!loadingItems
          .find(item => item.loading === loading);
        return canContinue;
      }),
    );
  }

  /**
   * the function emits event
   * only when into `loadingItems` has item which should turn `local` loading
   *
   * @param null
   *
   * @return `void`
   */
  listenLoadingByName(name: string) {
    return this.loadingChange.asObservable().pipe(
      debounceTime(10),
      filter((loadingItems) => {
        return loadingItems?.length === 0 ||
          !!loadingItems?.find(item => item.name === name);
      }),
      map(loadingItems => {
        const canContinue = !!loadingItems
          .find(item => item.name === name);
        return canContinue;
      })
    );
  }
}
