import { I2covetAddRequest } from '@models/index';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CovetService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('COVET_ENDPOINT') private _endpoint: string,
  ){
  }

  public processArtnetForm(data: I2covetAddRequest): Observable<void> {
    return this._httpClient.post<void>(`${this._endpoint}/add`, data);
  }
}
