export const AFFORTABLE_COLORS: {key: string, value: string}[] = [
    // 'rgb(0, 0, 0)',
    // 'rgb(126, 84, 64)',
    // 'rgb(229, 2, 2)',
    // 'rgb(229, 115, 2)',
    // 'rgb(254, 216, 31)',
    // 'rgb(231, 29, 115)',
    // 'rgb(27, 42, 156)',
    // 'rgb(108, 150, 231)',
    // 'rgb(10, 76, 36)',
    // 'rgb(96, 135, 34)',
    // 'rgb(183, 183, 183)',
    // 'rgb(222, 198, 179)',
    // 'rgb(255, 255, 255)',
    // 'linear-gradient(270deg,#b620e0,#6236ff,#0091ff,#6dd400,#f7b500,#fa6400,#e02020)',
    // 'rgb(150, 217, 225)',
    // 'rgb(205, 127, 50)',
    // 'rgb(255, 229, 180)'

    // 'black',
    // 'orange',
    // 'yellow',
    // 'green',
    // 'blue',
    // 'purple',
    // 'pink',
    // 'red',
    // 'white',
    // 'blacknwhite',
    // 'grey',
    // 'cream',
    // 'brown',
    // 'silver',
    // 'gold',
    // 'bronze',
    // 'clear',

    {key: 'black', value: 'rgb(0, 0, 0)'},
    {key: 'orange', value: 'rgb(255, 127, 0)'},
    {key: 'yellow', value: 'rgb(255, 255, 0)'},
    {key: 'green', value: 'rgb(0, 255, 0)'},
    {key: 'blue', value: 'rgb(0, 0, 255)'},
    {key: 'purple', value: 'rgb(128, 0, 128)'},
    {key: 'pink', value: 'rgb(255, 192, 203)'},
    {key: 'red', value: 'rgb(255, 0, 0)'},
    {key: 'white', value: 'rgb(255, 255, 255)'},
    {key: 'blacknwhite', value: 'linear-gradient(90deg,#000,#000 49%,#fff 0)'},
    {key: 'grey', value: 'rgb(128, 128, 128)'},
    {key: 'cream', value: 'rgb(255, 253, 208)'},
    {key: 'brown', value: 'rgb(150, 75, 0)'},
    {key: 'silver', value: 'rgb(192, 192, 192)'},
    {key: 'gold', value: 'rgb(212, 175, 55)'},
    {key: 'bronze', value: 'rgb(205, 127, 50)'},
    {key: 'multi multicolor', value: 'linear-gradient(270deg,#b620e0,#6236ff,#0091ff,#6dd400,#f7b500,#fa6400,#e02020)'},
    {key: '', value: 'transparent'},

];
