export const AFFORTABLE_STYLES: string[] = [
    "Abstract",
    "Abstract (geometric)",
    "Classic",
    "Conceptual",
    "Contemporary",
    "Documentary",
    "Expressionist",
    "Figurative",
    "Folk Art",
    "Illustrative",
    "Impressionist",
    "Minimalist",
    "Modernist",
    "Naïve",
    "Pop Art",
    "Realist",
    "Street Art",
    "Surrealist",
    "Typographic"
]