import { IArtnetAddRequest, IQueryParams, IArtistList, ApiResponse } from '@models/index';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('TWITTER_ENDPOINT') private _endpoint: string,
  ){ }

  public processTwitterForm(data: any) {
   return this._httpClient.post(`${this._endpoint}/tweet`, data);
  }

}
