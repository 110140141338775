export const FROALA_OPTIONS = {
    placeholder: 'Type your blog',
    toolbarButtons: {
        moreText: {
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
        },
        moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
        },
        moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
        },
        moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help', 'modal']
        },
    },
    attribution: false,
    key: 'XAG4eG4C2B7C10D6D6C-11VKOJ1FGULVKHXDXNDXc1d1Kg1SNdD5B4A4C3H3I3J3B7A4D3==',
    imageUploadURL: `froala-image-upload`,
    imageUploadMethod: 'POST'
};
