import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseApiService } from '@api';
import { IIntegrationRequest } from '@models/index';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, LoadingService } from '@services/index';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Cleaner } from './core/helpers/cleaner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Cleaner implements OnInit {

  loading$?: Observable<boolean>;

  constructor(
    private _translateSrv: TranslateService,
    private _configSrv: ConfigService,
    private _loadingSrv: LoadingService,
    private _activatedRoute: ActivatedRoute,
    private _baseAPI: BaseApiService
  ) {
    super();
  }

  ngOnInit() {
    this.loading$ = this._loadingSrv.listenGlogalLoading();
    this._getIntegrationData();
  }

  private _getIntegrationData() {
    this._activatedRoute.queryParams
      .pipe(
        switchMap((params) => {
          if (params.id) {
            const { id, cId, name } = params;
            const data: IIntegrationRequest = { stock_number: id, type: name, cId };
            return this._baseAPI.integration.getDataByStockNumberAndType(data);
          } else {
            return of();
          }
        })
      )
      .subscribe(res => {
      });
  }
}
