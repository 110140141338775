import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IIntegrationRequest } from '@models/index';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  public onIntegrationDataReceived$: Subject<any> = new Subject<any>();

  constructor(
    private _httpClient: HttpClient,
    @Inject('INTEGRATION_ENDPOINT') private _endpoint: string) { }

  public getDataByStockNumberAndType(data: IIntegrationRequest) {
    let params = new HttpParams();
    params = params.set('type', data.type);
    params = params.set('stock_number', data.stock_number);
    const headers = new HttpHeaders().set('c-id', data.cId);

    return this._httpClient.get<void>(`${this._endpoint}`, { params, headers })
      .pipe(
        map((response) => {
          this.onIntegrationDataReceived$.next(response);
          return response;
        })
      );
  }
}
