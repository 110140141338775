import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { ApiResponse, IBadaCreateRequest } from '@models/index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BadaService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('BADA_ENDPOINT') private _endpoint: string,
  ) { }

  public get(data: { key: string, stock_number: string }, cId: string, token: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    // return this._httpClient.post<ApiResponse<IArtnetAdd>>(`${environment.API_URL}/integration-get-stock`, data, { headers });
    return this._httpClient.post<ApiResponse<IBadaCreateRequest>>
    (`${environment.API_URL}/integration-get-stock`, data, { headers });
  }

  public add(data: IBadaCreateRequest, token: string, cId: string) {
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('c-id', cId);
    return this._httpClient.post<{ id: string }>(`${environment.API_URL}/integration-add`, data, {headers});
  }

  public update(data: IBadaCreateRequest, token: string, cId: string) {
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('c-id', cId);
    return this._httpClient.put(`${environment.API_URL}/integration-update/${data.stock_number}`, data, {headers});
  }

  public getCategories() {
    return this._httpClient.get(`${this._endpoint}/categories`);
  }

}
