import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Components } from '@components/export';
import { Directives } from '@directives/export';
import { Modals } from '@modals/export';
import { TranslateModule } from '@ngx-translate/core';
import { Pipes } from '@pipes/export';


@NgModule({
  declarations: [
    ...Pipes,
    ...Components,
    ...Modals,
    ...Directives,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule
  ],
  exports: [
    ...Components,
    ...Pipes,
    ...Directives,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class SharedModule { }
