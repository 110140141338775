// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const url = 'http://46.101.51.105';
export const environment = {
  production: false,
  // BADA_ENDPOINT: `${url}:4100`,
  // AFFORTABLE_ENDPOINT: `${url}:4080`,
  // ARTNET_ENDPOINT: `${url}:4140`,
  // ETSY_ENDPOINT: `${url}:4110`,
  // LAPADA_ENDPOINT: `${url}:4120`,
  // FIRST_DIBS_ENDPOINT: `${url}:4060`,
  // ARTSY_ENDPOINT: `${url}:4090`,
  // COVET_ENDPOINT: `${url}:4070`,
  // SELLING_ENDPOINT: `${url}:4130`,
  // TWITTER_ENDPOINT: `${url}:4300`,


  BADA_ENDPOINT: 'http://46.101.44.66:4100',
  AFFORTABLE_ENDPOINT: 'http://10.0.0.32:4080',
  ARTNET_ENDPOINT: 'http://10.0.0.32:4140',
  ETSY_ENDPOINT: 'http://10.0.0.32:4110',
  LAPADA_ENDPOINT: 'http://46.101.44.66:4120',
  FIRST_DIBS_ENDPOINT: 'http://10.0.0.40:4060',
  ARTSY_ENDPOINT: 'http://10.0.0.32:4090',
  COVET_ENDPOINT: 'http://10.0.0.32:4070',
  SELLING_ENDPOINT: 'http://46.101.44.66:4130',
  TWITTER_ENDPOINT: 'http://46.101.44.66:4300',

  // finally =====================================================
  API_URL: 'https://dev01.seekunique.co/api',
  // ==============================================================

  // MARKETPLACE_ENDPOINT: 'http://10.0.0.32:4130',
  INTEGRATION_ENDPOINT: 'https://dev01.seekunique.co/api/get-stock',
  // ADMIN_ENDPOINT: 'https://admin.seekunique.co',
  ADMIN_ENDPOINT: 'https://dev01admin.seekunique.co/',
  // ADMIN_ENDPOINT: 'http://integration.seekunique.co'

  // INTEGRATION_ENDPOINT: 'https://dev01.seekunique.co/api/stock'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
