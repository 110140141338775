export const TECHNIQUES = [
  'Amsterdam School', 'Anodized', 'Appliqué', 'Batik', 'Beaded', 'Beveled', 'Blackened', 'Bleached', 'Boulle', 'Bronzed',
  'Brushed', 'Burnished', 'Caning', 'Carved', 'Cast', 'Cerused', 'Chamfered', 'Champlevé', 'Cloissoné', 'Cold-Painted',
  'Cross-Banded', 'Dyed', 'Découpage', 'Ebonized', 'Embossed', 'Embroidered', 'Enameled', 'Engraved', 'Etched',
  'Faceted', 'Faux Bois', 'Fired', 'Forged', 'Fretwork', 'Frosted', 'Galvanized', 'Gilt', 'Glazed', 'Hammered',
  'Hand-Carved', 'Hand-Crafted', 'Hand-Knotted', 'Hand-Painted', 'Hand-Woven', 'Ikat', 'Inlay', 'Japanned',
  'Joinery', 'Kalamkari', 'Lacquered', 'Laminated', 'Limed', 'Machine-Made', 'Marquetry', 'Mercerized', 'Metalwork',
  'Molded', 'Mosaic', 'Needlepoint', 'Needlework', 'Niello', 'Oiled', 'Other', 'Painted', 'Parquetry', 'Patchwork',
  'Patinated', 'Plated', 'Polished', 'Polychromed', 'Powder-Coated', 'Pressed', 'Quilted', 'Repoussé', 'Sandblasted',
  'Silvered', 'Spun', 'Stained', 'Tempered', 'Turned', 'Unglazed', 'Varnished', 'Vegetable Dyed', 'Veneer',
  'Verre Églomisé', 'Welded', 'Woodwork', 'Woven'
];
