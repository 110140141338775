import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { IFirstdibsAddRequest } from '@models/index';

@Injectable({
  providedIn: 'root'
})

export class FirstDibs {
  constructor(
    private _httpClient: HttpClient,
    @Inject('FIRST_DIBS_ENDPOINT') private _endpoint: string
  ) { }

  public processFirstDibsForm(data: FormData): Observable<void> {
   return  this._httpClient.post<void>(`${this._endpoint}/add`, data);
  }
}
