import { IArtnetAddRequest, IQueryParams, IArtistList, ApiResponse } from '@models/index';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('ARTNET_ENDPOINT') private _endpoint: string,
  ){
  }


  public processInstagramForm(data: IArtnetAddRequest, cId: string, token: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.post<void>(`https://dev01.seekunique.co/api/integration-add`, data, {headers});
  }

  // public processArtnetGet(data: {key: string, stock_number: string}, cId: string, token: string): Observable<any> {
  //   let headers = new HttpHeaders();
  //   headers = headers.append('c-id', cId);
  //   headers = headers.append('token', token);
  //   return this._httpClient.post<ApiResponse<IArtnetAdd>>(`https://dev01.seekunique.co/api/integration-get-stock`, data, {headers} );
  // }

  // public processArtnetUpdate(data: IArtnetAddRequest, cId: string, token: string): Observable<void> {
  //   let headers = new HttpHeaders();
  //   headers = headers.append('c-id', cId);
  //   headers = headers.append('token', token);
  //   return this._httpClient.put<void>(`https://dev01.seekunique.co/api/integration-update/${data.stock_number}`, data, {headers} );
  // }
}
