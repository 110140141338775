export const AFFORTABLE_SUBJECTS: string[] = [
    "Landscape",
    "Floral",
    "Cityscape",
    "Nature",
    "Still life",
    "Science & Technology",
    "Nude",
    "Fashion",
    "Food",
    "Animals & Wildlife",
    "Human Figure",
    "Transport",
    "Seascape & Coastal",
    "Portraiture",
    "Celebrity",
    "Sport & Leisure",
    "Architecture",
    "Music",
    "Politics",
    "Myths & Legends",
    "Interiors",
    "Literature",
    "Maps",
    "Satire",
    "Urban",
    "Astrology",
    "Botanical",
    "Fantasy",
    "Feminism",
    "History",
    "Comics & Cartoons"
]