import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EtsyService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('ETSY_ENDPOINT') private _endpoint: string,

  ) { }

  public processEtsyForm(data: FormData): Observable<void> {
    return this._httpClient.post<void>(`${this._endpoint}/add`, data);
  }
}
