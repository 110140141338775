export const PRICE_BRACKETS = [
    {
        value: '_none',
        label: 'N/A'
    },
    {
        value: '577',
        label: '£1 - £4,999'
    },
    {
        value: '578',
        label: '5,000 - £24,999'
    },
    {
        value: '579',
        label: '£25,000 - £49,999'
    },
    {
        value: '580',
        label: '£50,000 - £99,999'
    },
    {
        value: '581',
        label: '£100,000 +'
    }
];
