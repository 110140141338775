export const COVET_MATERIALS = [
  {
    'title': 'Bone & Horn',
    'slug': 'bone-horn'
  },
  {
    'title': 'Books & Atlases',
    'slug': 'books-atlases'
  },
  {
    'title': 'Ceramics',
    'slug': 'ceramics'
  },
  {
    'title': 'Pottery',
    'slug': 'pottery'
  },
  {
    'title': 'Porcelain',
    'slug': 'porcelain'
  },
  {
    'title': 'Creamware',
    'slug': 'creamware'
  },
  {
    'title': 'Delft',
    'slug': 'delft'
  },
  {
    'title': 'Faience',
    'slug': 'faience'
  },
  {
    'title': 'Hispano-Moresque',
    'slug': 'hispanomoresque'
  },
  {
    'title': 'Ironstone',
    'slug': 'ironstone'
  },
  {
    'title': 'Lustreware',
    'slug': 'lustreware'
  },
  {
    'title': 'Maiolica',
    'slug': 'maiolica'
  },
  {
    'title': 'Stoneware',
    'slug': 'stoneware'
  },
  {
    'title': 'Terracotta',
    'slug': 'terracotta'
  },
  {
    'title': 'Cloisonne',
    'slug': 'cloisonne'
  },
  {
    'title': 'Enamel',
    'slug': 'enamel'
  },
  {
    'title': 'Bronze',
    'slug': 'bronze'
  },
  {
    'title': 'Fine Arts',
    'slug': 'fine-arts'
  },
  {
    'title': 'Paintings',
    'slug': 'paintings'
  },
  {
    'title': 'Acrylic',
    'slug': 'acrylic'
  },
  {
    'title': 'Mixed Media Art',
    'slug': 'mixed-media-art'
  },
  {
    'title': 'Oil',
    'slug': 'oil'
  },
  {
    'title': 'Watercolours',
    'slug': 'watercolours'
  },
  {
    'title': 'Drawings and Etchings',
    'slug': 'drawings-and-etchings'
  },
  {
    'title': 'Photographs',
    'slug': 'photographs'
  },
  {
    'title': 'Posters',
    'slug': 'posters'
  },
  {
    'title': 'Prints',
    'slug': 'prints'
  },
  {
    'title': 'Gemstones',
    'slug': 'gemstones'
  },
  {
    'title': 'Agate',
    'slug': 'agate'
  },
  {
    'title': 'Alexandrite',
    'slug': 'alexandrite'
  },
  {
    'title': 'Amber',
    'slug': 'amber'
  },
  {
    'title': 'Amethyst',
    'slug': 'amethyst'
  },
  {
    'title': 'Aquamarine',
    'slug': 'aquamarine'
  },
  {
    'title': 'Aventurine',
    'slug': 'aventurine'
  },
  {
    'title': 'Blue John',
    'slug': 'blue-john'
  },
  {
    'title': 'Carnelian',
    'slug': 'carnelian'
  },
  {
    'title': 'Citrine',
    'slug': 'citrine'
  },
  {
    'title': 'Copal',
    'slug': 'copal'
  },
  {
    'title': 'Coral',
    'slug': 'coral'
  },
  {
    'title': 'Diamond',
    'slug': 'diamond'
  },
  {
    'title': 'Emerald',
    'slug': 'emerald'
  },
  {
    'title': 'Garnet',
    'slug': 'garnet'
  },
  {
    'title': 'Jade',
    'slug': 'jade'
  },
  {
    'title': 'Jasper',
    'slug': 'jasper'
  },
  {
    'title': 'Jet',
    'slug': 'jet'
  },
  {
    'title': 'Lapis lazuli',
    'slug': 'lapis-lazuli'
  },
  {
    'title': 'Malachite',
    'slug': 'malachite'
  },
  {
    'title': 'Marcasite',
    'slug': 'marcasite'
  },
  {
    'title': 'Moonstone',
    'slug': 'moonstone'
  },
  {
    'title': 'Onyx',
    'slug': 'onyx'
  },
  {
    'title': 'Opal',
    'slug': 'opal'
  },
  {
    'title': 'Peridot',
    'slug': 'peridot'
  },
  {
    'title': 'Quartz',
    'slug': 'quartz'
  },
  {
    'title': 'Ruby',
    'slug': 'ruby'
  },
  {
    'title': 'Sapphire',
    'slug': 'sapphire'
  },
  {
    'title': 'Spinel',
    'slug': 'spinel'
  },
  {
    'title': 'Sunstone',
    'slug': 'sunstone'
  },
  {
    'title': 'Tanzanite',
    'slug': 'tanzanite'
  },
  {
    'title': 'Tiger\'s Eye',
    'slug': 'tigers-eye'
  },
  {
    'title': 'Topaz',
    'slug': 'topaz'
  },
  {
    'title': 'Turquoise',
    'slug': 'turquoise'
  },
  {
    'title': 'Gilt',
    'slug': 'gilt'
  },
  {
    'title': 'Glass',
    'slug': 'glass'
  },
  {
    'title': 'Ivory',
    'slug': 'ivory'
  },
  {
    'title': 'Lacquer',
    'slug': 'lacquer'
  },
  {
    'title': 'Leatherwork & Hide',
    'slug': 'leatherwork-hide'
  },
  {
    'title': 'Metalwork & Metalware',
    'slug': 'metalwork-metalware'
  },
  {
    'title': 'Brass & Brassware',
    'slug': 'brass-brassware'
  },
  {
    'title': 'Copper & Copperware',
    'slug': 'copper-copperware'
  },
  {
    'title': 'Ironwork',
    'slug': 'ironwork'
  },
  {
    'title': 'Ormolu',
    'slug': 'ormolu'
  },
  {
    'title': 'Paktong',
    'slug': 'paktong'
  },
  {
    'title': 'Pewter & Base Metals',
    'slug': 'pewter-base-metals'
  },
  {
    'title': 'Spelter',
    'slug': 'spelter'
  },
  {
    'title': 'Steel',
    'slug': 'steel'
  },
  {
    'title': 'Toleware',
    'slug': 'toleware'
  },
  {
    'title': 'Zinc',
    'slug': 'zinc'
  },
  {
    'title': 'Paper & Card',
    'slug': 'paper-card'
  },
  {
    'title': 'Papier-mâché',
    'slug': 'papier-mache'
  },
  {
    'title': 'Pearl & Shell',
    'slug': 'pearl-shell'
  },
  {
    'title': 'Rubber',
    'slug': 'rubber'
  },
  {
    'title': 'Plaster of Paris',
    'slug': 'plaster-of-paris'
  },
  {
    'title': 'Silver & Precious Metals',
    'slug': 'silver-precious-metals'
  },
  {
    'title': 'Silver',
    'slug': 'silver'
  },
  {
    'title': 'Gold',
    'slug': 'gold'
  },
  {
    'title': 'Platinum',
    'slug': 'platinum'
  },
  {
    'title': 'Silver Plate',
    'slug': 'silver-plate'
  },
  {
    'title': 'Sheffield Plate',
    'slug': 'sheffield-plate'
  },
  {
    'title': 'Taxidermy',
    'slug': 'taxidermy'
  },
  {
    'title': 'Textiles',
    'slug': 'textiles'
  },
  {
    'title': 'Tortoiseshell',
    'slug': 'tortoiseshell'
  },
  {
    'title': 'Wax',
    'slug': 'wax'
  },
  {
    'title': 'Wood',
    'slug': 'wood'
  },
  {
    'title': 'Alder',
    'slug': 'alder'
  },
  {
    'title': 'Amboyna',
    'slug': 'amboyna'
  },
  {
    'title': 'Applewood',
    'slug': 'applewood'
  },
  {
    'title': 'Ash',
    'slug': 'ash'
  },
  {
    'title': 'Balsa',
    'slug': 'balsa'
  },
  {
    'title': 'Bamboo',
    'slug': 'bamboo'
  },
  {
    'title': 'Beech',
    'slug': 'beech'
  },
  {
    'title': 'Birch',
    'slug': 'birch'
  },
  {
    'title': 'Boxwood',
    'slug': 'boxwood'
  },
  {
    'title': 'Camphor',
    'slug': 'camphor'
  },
  {
    'title': 'Cedar',
    'slug': 'cedar'
  },
  {
    'title': 'Cherry',
    'slug': 'cherry'
  },
  {
    'title': 'Chestnut',
    'slug': 'chestnut'
  },
  {
    'title': 'Coromandel',
    'slug': 'coromandel'
  },
  {
    'title': 'Ebony',
    'slug': 'ebony'
  },
  {
    'title': 'Elm',
    'slug': 'elm'
  },
  {
    'title': 'Fruitwood',
    'slug': 'fruitwood'
  },
  {
    'title': 'Gilt Wood',
    'slug': 'gilt-wood'
  },
  {
    'title': 'Goncalo Alvez',
    'slug': 'goncalo-alvez'
  },
  {
    'title': 'Hickory',
    'slug': 'hickory'
  },
  {
    'title': 'Kingwood',
    'slug': 'kingwood'
  },
  {
    'title': 'Laburnum',
    'slug': 'laburnum'
  },
  {
    'title': 'Lacewood',
    'slug': 'lacewood'
  },
  {
    'title': 'Lignum Vitae',
    'slug': 'lignum-vitae'
  },
  {
    'title': 'Limewood',
    'slug': 'limewood'
  },
  {
    'title': 'Mahogany',
    'slug': 'mahogany'
  },
  {
    'title': 'Malacca',
    'slug': 'malacca'
  },
  {
    'title': 'Maple',
    'slug': 'maple'
  },
  {
    'title': 'Oak',
    'slug': 'oak'
  },
  {
    'title': 'Olive',
    'slug': 'olive'
  },
  {
    'title': 'Oriental Hardwood',
    'slug': 'oriental-hardwood'
  },
  {
    'title': 'Pear',
    'slug': 'pear'
  },
  {
    'title': 'Pine٫ Spruce',
    'slug': 'pine-spruce'
  },
  {
    'title': 'Poplar',
    'slug': 'poplar'
  },
  {
    'title': 'Rosewood',
    'slug': 'rosewood'
  },
  {
    'title': 'Sandalwood',
    'slug': 'sandalwood'
  },
  {
    'title': 'Satinwood',
    'slug': 'satinwood'
  },
  {
    'title': 'Snakewood',
    'slug': 'snakewood'
  },
  {
    'title': 'Sycamore',
    'slug': 'sycamore'
  },
  {
    'title': 'Teak',
    'slug': 'teak'
  },
  {
    'title': 'Thuya',
    'slug': 'thuya'
  },
  {
    'title': 'Tulipwood',
    'slug': 'tulipwood'
  },
  {
    'title': 'Walnut',
    'slug': 'walnut'
  },
  {
    'title': 'Willow',
    'slug': 'willow'
  },
  {
    'title': 'Yew',
    'slug': 'yew'
  },
  {
    'title': 'Marble',
    'slug': 'marble'
  },
  {
    'title': 'Goatskin',
    'slug': 'goatskin'
  },
  {
    'title': 'Lucite',
    'slug': 'lucite'
  },
  {
    'title': 'Lambswool',
    'slug': 'lambswool'
  },
  {
    'title': 'Nickel',
    'slug': 'nickel'
  },
  {
    'title': 'Other Porcelain',
    'slug': 'other-porcelain'
  },
  {
    'title': 'Stoneglaze',
    'slug': 'stoneglaze'
  },
  {
    'title': 'Velvet',
    'slug': 'velvet'
  },
  {
    'title': 'Silkwork',
    'slug': 'silkwork'
  },
  {
    'title': 'Embroidery',
    'slug': 'embroidery'
  },
  {
    'title': 'Harewood',
    'slug': 'harewood'
  },
  {
    'title': 'Fishskin',
    'slug': 'fishskin'
  },
  {
    'title': 'Alabaster',
    'slug': 'alabaster'
  },
  {
    'title': 'Linen',
    'slug': 'linen'
  },
  {
    'title': 'Crystal',
    'slug': 'crystal'
  },
  {
    'title': 'Stone China',
    'slug': 'stone-china'
  },
  {
    'title': 'Upholstery',
    'slug': 'upholstery'
  },
  {
    'title': 'Chrome',
    'slug': 'chrome'
  },
  {
    'title': 'Purpleheart Wood',
    'slug': 'purpleheart-wood'
  },
  {
    'title': 'Vitreous Paste',
    'slug': 'vitreous-paste'
  },
  {
    'title': 'Paduok',
    'slug': 'paduok'
  },
  {
    'title': 'Walnut-burr',
    'slug': 'walnutburr'
  },
  {
    'title': 'Huanghuali',
    'slug': 'huanghuali'
  },
  {
    'title': 'Tourmaline',
    'slug': 'tourmaline'
  },
  {
    'title': 'Limestone',
    'slug': 'limestone'
  },
  {
    'title': 'Charcoal',
    'slug': 'charcoal'
  },
  {
    'title': 'Shagreen',
    'slug': 'shagreen'
  },
  {
    'title': 'Black Forest Wood',
    'slug': 'black-forest-wood'
  },
  {
    'title': 'Lindenwood',
    'slug': 'lindenwood'
  },
  {
    'title': 'Polychrome',
    'slug': 'polychrome'
  },
  {
    'title': 'Hardstone',
    'slug': 'hardstone'
  },
  {
    'title': 'Coconut',
    'slug': 'coconut'
  },
  {
    'title': 'Wool',
    'slug': 'wool'
  },
  {
    'title': 'Silk',
    'slug': 'silk'
  },
  {
    'title': 'Marquetry',
    'slug': 'marquetry'
  },
  {
    'title': 'Suede',
    'slug': 'suede'
  },
  {
    'title': 'White Gold',
    'slug': 'white-gold'
  },
  {
    'title': 'Plexiglass',
    'slug': 'plexiglass'
  },
  {
    'title': 'Wicker',
    'slug': 'wicker'
  },
  {
    'title': 'Canvas',
    'slug': 'canvas'
  },
  {
    'title': 'Shearling',
    'slug': 'shearling'
  }
];
