import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { ApiResponse, IArtistList, IArtnetAddRequest } from '@models/index';
import { Observable } from 'rxjs';
import { IArtnetAdd } from './../models/artnet/artnet';

@Injectable({
  providedIn: 'root'
})
export class ArtnetService {
  private _baseURL: string = 'https://dev01.seekunique.co/api/';
  constructor(
    private _httpClient: HttpClient,
    @Inject('ARTNET_ENDPOINT') private _endpoint: string,
  ) {
  }

  public getArtists(key: string, cId: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.get<IArtistList>(`${environment.API_URL}integration-get-artists/${key}`, { headers });
  }

  public processArtnetForm(data: IArtnetAddRequest, cId: string, token: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.post<void>(`${environment.API_URL}integration-add`, data, { headers });
  }

  public processArtnetGet(data: { key: string, stock_number: string }, cId: string, token: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.post<ApiResponse<IArtnetAdd>>(`${environment.API_URL}integration-get-stock`, data, { headers });
  }

  public processArtnetUpdate(data: IArtnetAddRequest, cId: string, token: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.put<void>(`${environment.API_URL}integration-update/${data.stock_number}`, data, { headers });
  }
}
