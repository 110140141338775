import { EtsyService } from '../etsy.service';
import { LapadaService } from '../lapada.service';
import { Injectable } from '@angular/core';
import { AffortableService } from '../affortable.service';
import { BadaService } from '../bada.service';
import { ArtnetService } from '../artnet.service';
import { FirstDibs } from '../first-dibs.service';
import { ArtsyService } from '../artsy.service';
import { CovetService } from '../2covet.service';
import { SellingService } from '../selling.service';
import { IntegrationService } from '../integration.service';
import { UtilsService } from '../utils.service';
import { InstagramService } from '../instagram.service';
import { TwitterService } from '../twitter.service';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  constructor(
    public bada: BadaService,
    public affortable: AffortableService,
    public artnet: ArtnetService,
    public lapada: LapadaService,
    public firstDibs: FirstDibs,
    public artsy: ArtsyService,
    public covet: CovetService,
    public selling: SellingService,
    public integration: IntegrationService,
    public etsy: EtsyService,
    public instagram: InstagramService,
    public utils: UtilsService,
    public twitter: TwitterService
  ) { }
}
