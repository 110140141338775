<div class="h-100 container-fluid d-flex flex-column justify-content-around align-items-center py-3">
  <div class="row">
    <div class="col-12 text-center">
      <div
        class="accent title mb-0"
        *ngIf="data?.title">
        <ng-container *ngIf="data?.title?.doNotTranslate; else titleTranslated">{{data.title?.value}}</ng-container>
        <ng-template #titleTranslated>
          {{ data.title.value | translate:data.title?.translateProperties }}
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-12 my-4"
      *ngIf="data?.desc">
      <div class="mat-body accent mb-0 text-center pre-view px-md-5 px-0">
        <ng-container *ngIf="data.desc?.doNotTranslate; else descTranslated">{{data.desc?.value}}</ng-container>
        <ng-template #descTranslated>
          {{ data.desc.value | translate:data.desc?.translateProperties }}
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center align-items-center">

      <button
        mat-flat-button
        *ngIf="data?.template === 0"
        class="mx-lg-3 mx-2 mat-button-sm"
        type="button"
        [color]="data?.cancel?.color"
        [ngClass]="data?.cancel?.class || 'btn-accent'"
        (click)="modalRef.close()">
        <ng-container *ngIf="data?.cancel?.doNotTranslate; else cancelTranslated">{{data?.cancel?.name}}</ng-container>
        <ng-template #cancelTranslated>
          {{ (data?.cancel?.name || 'common.cancel') | translate:data?.cancel?.translateProperties }}
        </ng-template>
      </button>

      <button
        mat-flat-button
        class="mx-lg-3 mx-2 mat-button-sm"
        type="button"
        [color]="data?.continue?.color || 'accent'"
        [ngClass]="data?.continue?.class"
        (click)="onContinue()">
        <ng-container *ngIf="data?.continue?.doNotTranslate; else cancelTranslated">{{data?.continue?.name}}</ng-container>
        <ng-template #cancelTranslated>
          {{ (data?.continue?.name || 'common.ok') | translate:data?.continue?.translateProperties }}
        </ng-template>
      </button>

    </div>
  </div>
</div>
