import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '@modals/modal/modal.component';
import { DefaultModalInputData, ModalOutputData } from '@models/index';
import { Observable } from 'rxjs/internal/Observable';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private _modal: MatDialog) { }

  /**
   * @param data is a modal input data with type `any` (by default `null`)
   * @param component is modal component wich one should openend. `component`
   *        type is `any` (by default is `ModalComponent`)
   * @param options is dialog configuration options (by default `null`)
   *
   * @return `Observable<ModalOutputData>`
   */
  open<T = null, K = DefaultModalInputData>(
    data: K | null,
    component: any = ModalComponent,
    options: MatDialogConfig | null,
  ): Observable<ModalOutputData<T>> {
    options = options || {};

    const dialogOptions = { data, ...options };

    const dialogRef = this._modal.open(component, dialogOptions);

    return dialogRef
      .afterClosed()
      .pipe(
        map((res: ModalOutputData<T>) => {
          if (!res) { res = { continue: false }; }
          return res;
        }),
        take(1)
      );
  }
}
