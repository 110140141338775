import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IArtsyAddRequest } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class ArtsyService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('ARTSY_ENDPOINT') private _endpoint: string) { }

    public processArtsyForm(data: IArtsyAddRequest): Observable <void> {
      return this._httpClient.post<void>(`${this._endpoint}/add`, data);
    }
}


