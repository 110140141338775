import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '1st-dibs',
    pathMatch: 'full'
  },
  {
    path: 'bada',
    loadChildren: () => import('./views/bada/bada.module').then(module => module.BadaModule)
  },
  {
    path: 'affordable_art_fair',
    loadChildren: () => import('./views/affordable/affordable.module').then(module => module.AffordableModule)
  },
  {
    path: '1stdibs',
    loadChildren: () => import('./views/first-dibs/first-dibs.module').then(module => module.FirstDibsModule)
  },
  {
    path: 'etsy',
    loadChildren: () => import('./views/listings/etsy.module').then(module => module.EtsyModule)
  },
  {
    path: 'lapada',
    loadChildren: () => import('./views/lapada/lapada.module').then(module => module.LapadaModule)
  },
  {
    path: 'artnet',
    loadChildren: () => import('./views/art-net/art-net.module').then(module => module.ArtNetModule)
  },
  {
    path: 'artsy',
    loadChildren: () => import('./views/artsy/artsy.module').then(module => module.ArtsyModule)
  },
  {
    path: '2covet',
    loadChildren: () => import('./views/2covet/2covet.module').then(module => module.CovetModule)
  },
  {
    path: 'selling_antiques',
    loadChildren: () => import('./views/sellingantiques/selling.module').then(module => module.SellingModule)
  },
  {
    path: 'instagram',
    loadChildren: () => import('./views/instagram/instagram.module').then(module => module.InstagramModule)
  },
  {
    path: 'facebook',
    loadChildren: () => import('./views/facebook/facebook.module').then(module => module.FacebookModule)
  },
  {
    path: 'twitter',
    loadChildren: () => import('./views/twitter/twitter.module').then(module => module.TwitterModule)
  },
  {
    path: 'pinterest',
    loadChildren: () => import('./views/pinterest/pinterest.module').then(module => module.PinterestModule)
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./views/marketplace/marketplace.module').then(module => module.MarketplaceModule)
  },
  // {
  //   path: 'twitter',
  //   loadChildren: () => import('./views/socials/twitter/twitter.module').then(module => module.TwitterModule)
  // }
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top'
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
