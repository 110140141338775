export const MATERIALS = [
  'Abalone', 'Acrylic', 'Agate', 'Alabaster', 'Alligator', 'Alpaca', 'Altuglas', 'Aluminum', 'Amboyna', 'Amethyst', 'Angora',
  'Animal Skin', 'Antler', 'Art Glass', 'Ash', 'Baize', 'Bakelite', 'Bamboo', 'Beads', 'Beech', 'Belgian Black Marble', 'Bentwood',
  'Berlin Iron', 'Birch', 'Birdseye Maple', 'Blown Glass', 'Bog Wood', 'Bone', 'Bouclé', 'Boxwood', 'Braid', 'Brass', 'Breccia Marble',
  'Britannia Standard Silver' , 'Brocade', 'Bronze', 'Bubinga', 'Burl', 'Burlap', 'Calico', 'Cane', 'Canvas', 'Carbon Fiber', 'Carnelian',
  'Carrara Marble', 'Cast Stone', 'Cedar', 'Celadon', 'Cement', 'Ceramic', 'Chenille', 'Cherry', 'Chestnut', 'Chrome', 'Clay',
  'Coade Stone', 'Coating', 'Cocobolo', 'Coconut', 'Composition', 'Concrete', 'Copper', 'Coral', 'Cord', 'Corian', 'Cork', 'Cotton',
  'Cowhide', 'Crayon', 'Creamware', 'Crocodile', 'Crystal', 'Cut Glass', 'Cut Steel', 'Cypress', 'Damask', 'Delft', 'Down', 'Driftwood',
  'EPNS', 'Earthenware', 'Ebony', 'Eggshell', 'Elm', 'Enamel', 'Epoxy Resin', 'Fabric', 'Faience', 'Faux Bamboo', 'Faux Fur',
  'Faux Leather', 'Feathers', 'Felt', 'Fiberglass', 'Fir', 'Foam', 'Foil', 'Formica', 'Fruitwood', 'Fur', 'Gesso', 'Giltwood',
  'Glass', 'Goat Hair', 'Goatskin', 'Gold', 'Gold Leaf', 'Gold Plate', 'Granadillo', 'Granite', 'Grasscloth', 'Griotte Marble',
  'Hardwood', 'Harewood', 'Hemp', 'Hickory', 'Hide', 'Holly', 'Horn', 'Imbuia', 'Iron', 'Ironstone', 'Ivory', 'Jacaranda', 'Jacquard',
  'Jade', 'Jute', 'Kaolin', 'Katalox', 'Kingwood', 'Korhogo Cloth', 'Kork', 'Lacquer', 'Lambskin', 'Laminate', 'Lapis Lazuli',
  'Latex', 'Lava', 'Lead', 'Leather', 'Limestone', 'Linen', 'Lizard', 'Lucite', 'Luster', 'Macassar', 'Machiche', 'Magnets',
  'Mahogany', 'Maiolica', 'Majolica', 'Malachite', 'Maple', 'Marble', 'Marine turtle', 'Martelé', 'Masonite', 'Meissen', 'Mercury Glass',
  'Metal', 'Metallic Thread', 'Milk Glass', 'Mirror', 'Mohair', 'Mother-of-Pearl', 'Multi-gemstone', 'Murano Glass', 'Murrine', 'Muslin',
  'Mylar', 'Natural Fiber', 'Naugahyde', 'Nickel', 'Nutwood', 'Nylon', 'Oak', 'Olive', 'Onyx', 'Opal', 'Opaline Glass', 'Optical Glass',
  'Organic Material', 'Ormolu', 'Ostrich Eggshell', 'Ostrich Leather', 'Other', 'PVC', 'Padouk', 'Paint', 'Paldao', 'Palisander',
  'Palmwood', 'Paper', 'Papercord', 'Parchment Paper', 'Paste', 'Patent Leather', 'Pearlware', 'Pearwood', 'Petrified Wood', 'Pewter',
  'Pine', 'Pink Coral', 'Plaster', 'Plastic', 'Platinum', 'Plexiglass', 'Plywood', 'Polyester', 'Polystyrene', 'Poplar', 'Porcelain',
  'Porphyry', 'Pottery', 'Precious Stone', 'Purpleheart', 'Pyrite', 'Python', 'Quartz', 'Raffia', 'Rattan', 'Reclaimed Wood', 'Reed',
  'Reeded Glass', 'Resin', 'Rhodoid', 'Ribbon', 'Rock Crystal', 'Rope', 'Rose Quartz', 'Rosewood', 'Rubber', 'Rush', 'Sandalwood',
  'Sandstone', 'Satin', 'Satinwood', 'Scagliola', 'Schist', 'Scrap Wood', 'Sea turtle', 'Seagrass', 'Sequins', 'Serpentine', 'Shagreen',
  'Shagreen Stingray', 'Sheepskin', 'Sheet Metal', 'Sheffield Plate', 'Shell', 'Siena Marble', 'Silicone', 'Silk', 'Silver', 'Silver Leaf',
  'Silver Plate', 'Slag Glass', 'Slate', 'Smoked Glass', 'Soapstone', 'Softwood', 'Sommerso', 'Spelter', 'Spruce', 'Stained Glass',
  'Stainless Steel', 'Statuary Marble', 'Steel', 'Sterling Silver', 'Stone', 'Stoneware', 'Straw', 'String', 'Stucco', 'Suede', 'Sycamore',
  'Synthetic', 'Sèvres', 'Talosel', 'Tapestry', 'Teak', 'Teeth', 'Terracotta', 'Terrazzo', 'Textile', 'Thread', 'Tin', 'Tortoise Shell',
  'Tourmaline', 'Travertine', 'Trimming', 'Tulipwood', 'Twig', 'Twill', 'Twine', 'Tôle', 'Ultrasuede', 'Upholstery', 'Uranium Glass',
  'Velvet', 'Vermeil', 'Vitrolite', 'Walnut', 'Wenge', 'Wicker', 'Willow', 'Wire', 'Wood', 'Wool', 'Wrought Iron', 'Yarn', 'Yew', 'Zebra Hide',
  'Zebra Wood', 'Zinc', 'Ziricote', 'Zitan'
];
