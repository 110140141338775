import { IAffortableMediumCategory } from '@models/index';

export const MEDIUMS: IAffortableMediumCategory[] = [
    {
        title: 'Collage',
        key: 'Collage',
        children: [
            {
                title: 'Mixed-media',
                key: 'Collage:Mixed-media',
                isLeaf: true
            },
            {
                title: 'Paper',
                key: 'Collage:Paper',
                isLeaf: true
            },
            {
                title: 'Fabric',
                key: 'Collage:Fabric',
                isLeaf: true
            },
            {
                title: 'Digital collage',
                key: 'Collage:Digital collage',
                isLeaf: true
            },
            {
                title: 'Found objects',
                key: 'Collage:Found objects',
                isLeaf: true
            },
            {
                title: 'Photographs',
                key: 'Collage:Photographs',
                isLeaf: true
            }
        ]
    },
    {
        title: 'Drawings',
        key: 'Drawings',
        children: [
            {
                title: 'Mixed-media',
                key: 'Drawings:Mixed-media',
                isLeaf: true
            },
            {
                title: 'Chalk',
                key: 'Drawings:Chalk',
                isLeaf: true
            },
            {
                title: 'Chinese ink',
                key: 'Drawings:Chinese ink',
                isLeaf: true
            },
            {
                title: 'Ink',
                key: 'Drawings:Ink',
                isLeaf: true
            },
            {
                title: 'Japanese ink',
                key: 'Drawings:Japanese ink',
                isLeaf: true
            },
            {
                title: 'Pen',
                key: 'Drawings:Pen',
                isLeaf: true
            },
            {
                title: 'Charcoal',
                key: 'Drawings:Charcoal',
                isLeaf: true
            },
            {
                title: 'Graphite',
                key: 'Drawings:Graphite',
                isLeaf: true
            },
            {
                title: 'Marker',
                key: 'Drawings:Marker',
                isLeaf: true
            },
            {
                title: 'Pastel',
                key: 'Drawings:Pastel',
                isLeaf: true
            },
            {
                title: 'Pencil',
                key: 'Drawings:Pencil',
                isLeaf: true
            },
            {
                title: 'Crayon',
                key: 'Drawings:Crayon',
                isLeaf: true
            }
        ]
    },
    {
        title: 'Paintings',
        key: 'Paintings',
        children: [
            {
                title: 'Acrylic',
                key: 'Paintings:Acrylic',
                isLeaf: true
            },
            {
                title: 'Gouache',
                key: 'Paintings:Gouache',
                isLeaf: true
            },
            {
                title: 'Household paint',
                key: 'Paintings:Household paint',
                isLeaf: true
            },
            {
                title: 'Industrial paint',
                key: 'Paintings:Industrial paint',
                isLeaf: true
            },
            {
                title: 'Mineral pigment paint',
                key: 'Paintings:Mineral pigment paint',
                isLeaf: true
            },
            {
                title: 'Mixed-media',
                key: 'Paintings:Mixed-media',
                isLeaf: true
            },
            {
                title: 'Oil paint',
                key: 'Paintings:Oil paint',
                isLeaf: true
            },
            {
                title: 'Spray paint',
                key: 'Paintings:Spray paint',
                isLeaf: true
            },
            {
                title: 'Watercolour',
                key: 'Paintings:Watercolour',
                isLeaf: true
            },
            {
                title: 'Ink',
                key: 'Paintings:Ink',
                isLeaf: true
            },
            {
                title: 'Enamel',
                key: 'Paintings:Enamel',
                isLeaf: true
            },
            {
                title: 'Encaustic',
                key: 'Paintings:Encaustic',
                isLeaf: true
            },
            {
                title: 'Tempera',
                key: 'Paintings:Tempera',
                isLeaf: true
            }
        ]
    },
    // {
    //     title: '',
    //     key: '',
    //     children: [
    //         {
    //             title: '',
    //             key: '',
    //             isLeaf: true
    //         }
    //     ]
    // }
];
