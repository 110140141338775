
 export const COVET_CATEGORIES = [
    {
      'title': 'Art',
      'slug': 'art',
      'description': '<p>Discover an extensive selection of Art for your home, from world renowned vetted dealers and galleries. Buy with confidence knowing you are dealing directly with well established art galleries and dealers. There are no commissions or inflated prices to pay when you shop via 2Covet. Our selection includes; contemporary art, original oil paintings dating from the 16th to mid-20th Century, vintage photography, drawings, prints, paintings, and sculptures.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/art.jpg',
      'level': '1',
      'categoryId': '1',
      'parentId': null
    },
    {
      'title': 'Asian Art',
      'slug': 'asian-art',
      'description': '<p>Authentic Asian Art directly from world renowned experts. All of our Asian Art dealers and galleries are vetted and well established. Our collection of Asian art works is ever growing and you can buy with confidence knowing you can deal directly with the experts. Our Asian Art experts have an incredible passion for their speciality. For sale are Asian metalwork, enamel, sculptures, netsuke, lacquer and okimono. Antique Asian works of art including Chinese, Japanese, Himalayan, Indian, Tibetan and Southeast Asian ceramics, porcelain, metalwork and textiles.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/asian-art.jpg',
      'level': '1',
      'categoryId': '2',
      'parentId': null
    },
    {
      'title': 'Furniture',
      'slug': 'furniture',
      'description': '<p>Discover our exceptional furniture, from exquisite antiques steeped in hundreds of years of history to modern day striking designs. By partnering with only the best antique dealers and interior designers our collection of exquisite furniture is full of rare and important exhibition quality pieces. Our ever growing collection of furniture includes one of a kind pieces from the greatest furniture makers in the world.</p><p>Many of our dealers specialise in 18th and 19th century English and Continental furniture, mirrors and related objects. However, our furniture collection also includes genuine Mid-century modern and iconic contemporary furniture. Find a truly special piece for your home or collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/furniture.jpg',
      'level': '1',
      'categoryId': '3',
      'parentId': null
    },
    {
      'title': 'Jewellery and Watches',
      'slug': 'jewellery-and-watches',
      'description': '<p>Our collection of unique and beautiful antique, contemporary and vintage jewellery and watches is compiled from world renowned experts. All of our jewellery and watch dealers are well established and well known in their specific fields. Our jewellery and watch category includes antique pieces some of which are hundreds of years old to well known designer watches from Rolex, Patek Philippe and Cartier. Own something incredible and grow your collection in complete confidence knowing you are able to deal directly with the experts. There are no commissions or inflated prices to pay on 2Covet.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/jewellery-%26-watches.jpg',
      'level': '1',
      'categoryId': '4',
      'parentId': null
    },
    {
      'title': 'Silver Ceramics and Glass',
      'slug': 'silver-ceramics-and-glass',
      'description': '<p>Browse our extensive collection of Silver, Ceramics and Glass. We have partnered with world famous Silver, Ceramics and Glass dealers to offer an unrivalled collection of unique pieces. Shop a collection which encompasses the Medieval, Renaissance and Baroque periods right up to modern one of a kind collectibles. We offer decorative mid-century and Murano glass alongside highly sought after early Silver and Ceramics. Buy with confidence and deal directly with world renowned dealers who have exceptional knowledge in their fields.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver-ceramics-glass.jpg',
      'level': '1',
      'categoryId': '5',
      'parentId': null
    },
    {
      'title': 'Specialist',
      'slug': 'specialist',
      'description': '<p>Our specialist category features antiques and designer items such as fashion, books, maps, luggage, maritime, nautical, scientific, sporting memorabilia and taxidermy. All of our specialist dealers are world renowned within their fields. Shop for something ‘one of a kind’ within our specialist category and buy with confidence knowing that all 2Covet dealers are well established experts. Shop in the knowledge that you are dealing directly with the dealers and that there are no commissions or inflated prices. Our specialist antique dealers have hundreds of years of knowledge and are happy to help grow collections or find the perfect gift.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/specialist.jpg',
      'level': '1',
      'categoryId': '6',
      'parentId': null
    },
    {
      'title': 'Editors Picks',
      'slug': 'editors-picks',
      'description': null,
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/art.jpg',
      'level': '1',
      'categoryId': '7',
      'parentId': null
    },
    {
      'title': '20th Century and Contemporary Art',
      'slug': '20th-century-and-contemporary-art',
      'description': '<p>Contemporary art direct from established art galleries and art dealers. Shop our extensive collection of contemporary art works from around the world. Contemporary art for sale from world famous galleries and dealers. From 1940’s to the present day, our collection of contemporary art is ever changing. Buy with confidence and with the knowledge that you are dealing directly with the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/20th-century.jpg',
      'level': '2',
      'categoryId': '9',
      'parentId': '1'
    },
    {
      'title': 'Bronzes',
      'slug': 'bronzes',
      'description': '<p>Early bronzes date all the way back to the Roman era, with pieces found within the renowned towns of Pompeii and Herculaneum, as well as being discovered in the burial tombs of Chinese Emperors in the ancient city of Mesopotamia.</p><p>During the Renaissance period, bronze statuettes saw a resurgence as European craftsmen endeavoured to model their ambitious designs on the Greco-Roman style of centuries past. Production began in Florence and rapidly extended throughout Italy and Northern Europe. These historically significant pieces were intended for private contemplation and for sharing amongst collectors as a symbolic break from the Catholic Church’s jurisdiction of artistic partisanship.</p><p>In contrast, up until the 19th century, Bronze statues in Britain were most commonly seen in civic spaces, with recognised bronzes of monarchs such as Charles I dating back to 1633. Between then and the early 19th century, bronze sculpting burgeoned, coming to a head during the period leading up the Great Exhibition in 1851. Demand for bronzes continued to develop in domestic households as a means of interior design, whilst the market for substantial outdoor bronze sculptures pivoted from war memorials towards monumental compositions.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/bronzes.jpg',
      'level': '2',
      'categoryId': '8',
      'parentId': '1'
    },
    {
      'title': 'Fine Art',
      'slug': 'fine-art',
      'description': '<p>Discover an ever growing selection of atmospheric landscapes and beautiful portraits. Direct from well established and world famous art galleries and art dealers finding the perfect addition to your art collection has never been easier. Our Fine Art collection consists of oil paintings, ranging from pre- Raphaelite and late Victorian artists to post-war paintings and maritime masterpieces, dating from the early 1600s to the present day. Immerse yourself in thought provoking fine art paintings and buy with confidence in the knowledge that you are dealing directly with the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/fine-art.jpg',
      'level': '2',
      'categoryId': '10',
      'parentId': '1'
    },
    {
      'title': 'Objet D’art',
      'slug': 'objet-d’art',
      'description': '<p>Objet D’art literally translates to ‘art object’ in French, but is used in English to characterise works of art that don’t fall into the typical art categories of paintings, sculptures, prints, drawings, ceramics, glass or textiles. The term can be somewhat broad but is often used to describe small three-dimensional objects, ranging from carvings and statuettes to engraved gems and non-utilitarian porcelain.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '2',
      'categoryId': '173',
      'parentId': '1'
    },
    {
      'title': 'Photography',
      'slug': 'photography',
      'description': '<p>Photography from world renowned dealers and photographers; included in our ever growing collection are atmospheric landscapes, astounding digital works and bold graphic abstracts. Discover beautiful art photography for sale and shop with the utmost confidence knowing you are dealing directly with well established galleries and dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/photography.jpg',
      'level': '2',
      'categoryId': '11',
      'parentId': '1'
    },
    {
      'title': 'Prints',
      'slug': 'prints',
      'description': '<p>Discover our unique collection of art prints compiled from the most celebrated galleries and artists. Our ever growing collection of prints includes antique and vintage prints to contemporary special editions. Buy with confidence and deal directly with the galleries and dealers. There are no commissions or inflated prices to pay and you communicate directly with the experts.</p>',
      'categoryImage': ' https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/prints.jpg',
      'level': '2',
      'categoryId': '12',
      'parentId': '1'
    },
    {
      'title': 'Sculptures',
      'slug': 'sculptures',
      'description': '<p>The sculpture selection available here at 2Covet is a collection that includes beautiful pieces from wood to stone, metal to marble. Add some dimension with carvings, castings and modelling, all made from exquisite materials that you can view from the comfort of your own home. The three dimensional nature of sculpture makes it arguably one of the most expressive and sensual art forms. Whether you are interested in our vast range of antiquity sculptures with heavy influences from ancient Greek art, or abstract and conceptual designs that move into the 19th century, you can buy with confidence knowing that the sculptures available on 2Covet are some of the finest in the world and sourced from our world renowned dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/sculpture.jpg',
      'level': '2',
      'categoryId': '13',
      'parentId': '1'
    },
    {
      'title': 'Interiors',
      'slug': 'interiors',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/interiors.jpg',
      'level': '3',
      'categoryId': '15',
      'parentId': '10'
    },
    {
      'title': 'Landscapes',
      'slug': 'landscapes',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/landscapes.jpg',
      'level': '3',
      'categoryId': '14',
      'parentId': '10'
    },
    {
      'title': 'Masters',
      'slug': 'masters',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '18',
      'parentId': '10'
    },
    {
      'title': 'Portraits',
      'slug': 'portraits',
      'description': '<p>The earliest portraiture dates back 5000 years, to around Ancient Egypt at the least. As well as a way of documenting someones appearance, portraits have been a way to express power, influence, wealth, beauty as well as an opportunity for the painter to provide the most flattering portrait of the sitter. More often than not, unfavourable paintings of the subject were dismissed.</p><p>Portraits are an important part of art history because they depict an insightful part of history during a time when photography may not have been available. The position, expression and style of the portrait is just as revealing as the location of the portrait and the surroundings of the subject. This can certainly add context to a historical context and provide deeper understanding of the cultures and zeitgeist of the time.</p><p>Modern portraiture often portrays friends, lovers of relatives of the artist and commissions have become further and far between. Moreover, photographic portraits have provided a new medium with a different perspective and an access to portraiture previously reserved for the luxury market.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/portraits.jpg',
      'level': '3',
      'categoryId': '17',
      'parentId': '10'
    },
    {
      'title': 'Seascapes',
      'slug': 'seascapes',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/seascapes.jpg',
      'level': '3',
      'categoryId': '16',
      'parentId': '10'
    },
    {
      'title': 'Vintage Photography',
      'slug': 'vintage-photography',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/vintage-photography.jpg',
      'level': '3',
      'categoryId': '21',
      'parentId': '11'
    },
    {
      'title': 'Chinese Works of Art',
      'slug': 'chinese-works-of-art',
      'description': '<p>Our collection of authentic Chinese Works of Art directly from world renowned experts. We offer a wide range of Chinese Works of Art such as Chinese art, ceramics, furniture, bronzes, sculptures, paintings from early China to the Qing dynasties. All of our Asian Art dealers and galleries are vetted and well established. Buy with confidence and deal directly with the dealers. There are no commissions or inflated prices to pay and you can communicate directly with the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/chinese.jpg',
      'level': '2',
      'categoryId': '22',
      'parentId': '2'
    },
    {
      'title': 'Himalyan Indian Tibetan Works of Art',
      'slug': 'himalyan-indian-tibetan-works-of-art',
      'description': '<p>Our collection of Himalyan, Indian, Tibetan and Other Asian works of art is an emerging collection, constantly expanding directly from our world renowned specialists and experts. We offer a range of Himalayan, Indian and Tibetan works of art; such as ceramics, bronzes, sculptures, furniture, pottery, paintings and textiles. Including modern masterpieces from Asia. All of our specialist Asian Art dealers and galleries are vetted and well established. Buy with confidence and deal directly with the specialist dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/indian.jpg',
      'level': '2',
      'categoryId': '24',
      'parentId': '2'
    },
    {
      'title': 'Japanese Works of Art',
      'slug': 'japanese-works-of-art',
      'description': '<p>Original Japanese Works of Art for sale directly from world renowned specialists and experts. We offer a wide range of Japanese works of art; such as Japanese ceramics, furniture, bronzes, sculptures and paintings from the Edo period to the sought after Meiji period. Including modern masterpieces from Japan. All of our Japanese Art dealers and galleries are vetted and well established. Buy with confidence and deal directly with the specialist dealers who can assist and help grow your collection of Japanese works of Art.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/japanese-works-of-art.jpg',
      'level': '2',
      'categoryId': '23',
      'parentId': '2'
    },
    {
      'title': 'Beds and Day Beds',
      'slug': 'beds-and-day-beds',
      'description': '<p>Shop our antique and vintage beds, bed frames, daybeds and chaise lounges. Our collection includes one of a kind antique beds made by world renowned makers such as Gillow’s. Our well established dealers also specialise in art deco and mid-century beds, bed frames and daybeds. All of our designers and dealers are vetted and extremely knowledgeable. Shop safe in the knowledge that there are no commissions or inflated prices to pay and you can communicate directly with the dealers, who can help source one of a kind antique beds, daybeds and bedframes for your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '2',
      'categoryId': '25',
      'parentId': '3'
    },
    {
      'title': 'Boxes and Caddies',
      'slug': 'boxes-and-caddies',
      'description': '<p>Antique, vintage, mid-century boxes and caddies available from established vetted and world famous dealers. We have a large selection of English and European antique boxes and caddies dating from as early as the 18th century. Surround yourself with exquisite accessories and deal directly with the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/boxes-and-caddies.jpg',
      'level': '2',
      'categoryId': '26',
      'parentId': '3'
    },
    {
      'title': 'Bookcases and Cabinets',
      'slug': 'bookcases-and-cabinets',
      'description': '<p>A one of a kind selection of Antique, Modern and Designer Bookcases and Cabinets. Our collection features 16th century period oak cabinets to modern day famous designers. Find exceptional pieces to fill any home and fit any style. Our specialist world famous antique furniture dealers specialise in unique bookcases and cabinets from periods in time which are often long forgotten, such as the Regency period, with its bold use of colour and contrast. These incredible antique bookcases and cabinets sit alongside our collection of modern designer furniture, created by world famous names such as Maitland Smith and Paul Evans. Shop our one of a kind selection to find the perfect bookcase and cabinet for your home and deal directly with the experts who can help source one of a kind pieces for your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/bookcase-cabinets.jpg',
      'level': '2',
      'categoryId': '27',
      'parentId': '3'
    },
    {
      'title': 'Buffets Commodes and Sideboards',
      'slug': 'buffets-commodes-and-sideboards',
      'description': '<p>Explore our range of buffets, commodes and sideboards. 2Covet offer a fantastic addition to any hallway, dining room or sitting room, providing an attractive storage space for any home. Displaying plates or serving food, buffets, commodes and sideboards offer the functionality you need to maximise space whilst offering organisation. Buffets, Commodes and Sideboards can also be a stunning part of your interior. 2Covet have a mix of both contemporary and antique pieces, spanning from the 17th - 21st century ensuring you find exactly what you are looking for with pieces ranging from Georgian sideboards to Louis XVI commodes.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/buffets-commodes-sideboards.jpg',
      'level': '2',
      'categoryId': '40',
      'parentId': '3'
    },
    {
      'title': 'Bureaus',
      'slug': 'bureaus',
      'description': '<p>In the early 17th century, the bureau plat was designed in France, as a simple flat table with drawers. This soon evolved and by the end of Louis XIV’s reign, tiered drawers surrounded each side with a singular drawer above the knees in the centre, with a sloping surface.</p>\n<p>English design followed after Charles II reign, as bureaus were widely described as ‘common desks with drawers under them’. By the 1690s, the bureau represented a combination of two furnitures; a chest of drawers and a desk, which became exceedingly popular during Queen Anne’s reign. Often combined with an array of features such as small drawers, pigeon holes and sliding bookends, the bureau became more established during the Georgian period, which may have included cock beading and a mahogany or oak hinged fall front. Victorian and Edwardian designs later included particularly decorative floral inlays.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/bureaus.jpg',
      'level': '2',
      'categoryId': '28',
      'parentId': '3'
    },
    {
      'title': 'Chests and Trunks',
      'slug': 'chests-and-trunks',
      'description': '<p>Discover our selection of antique chests and trunks in a variety of styles, shapes and sizes to suit your home interior needs. From 17th century walnut chests to 19th century military campaign trunks and everything in between, you’ll find the perfect addition to your collection. Featuring collections from the very best dealers in the country, you can shop in confidence in the knowledge that you are dealing with the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/chests-and-trunks.jpg',
      'level': '2',
      'categoryId': '29',
      'parentId': '3'
    },
    {
      'title': 'Clocks and Barometers',
      'slug': 'clocks-and-barometers',
      'description': '<p>Our Clock and Barometer Collection features incredible timepieces from makers highly skilled in the art of horology alongside a selection of beautiful antique barometers. Discover incredibly rare antique mantel clocks to the simply beautiful sleek modern timepieces we have on offer from world famous dealers. Included in our collection you can find every style including bracket clocks, wall clocks and longcase (grandfather) clocks, from some of the worlds finest makers. 2Covet also hosts a variety of rare and unique pieces including cased Edwardian barographs, Victorian pocket barometers, ornate 19th century wall barometers and antique ships barometers. Browse and purchase with confidence from recognised dealers who are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/clocks.jpg',
      'level': '2',
      'categoryId': '30',
      'parentId': '3'
    },
    {
      'title': 'Decorative Objects',
      'slug': 'decorative-objects',
      'description': '<p>Discover a multitude of antique decorative objects including ornate barometers, period clocks, decorative tea caddies and breathtaking vases. Our collection features an extensive range of decorative pieces from the 17th to 19th century in a variety of styles and periods. All our items can be bought with confidence, as we work with the country’s very best dealers who are experts in their fields.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/decorative-objects.jpg',
      'level': '2',
      'categoryId': '31',
      'parentId': '3'
    },
    {
      'title': 'Desks and Writing Tables',
      'slug': 'desks-and-writing-tables',
      'description': '<p>Our fine collection of desks, writing tables and bureaux date back as early as the 17th century. Included in this category are ornate writing tables constructed in rosewood, with extensive parcel gilt highlights, adorned with boxwood line inlay which adds an air of grandness to any room. Including pieces from the Georgian, Victorian and Edwardian eras, find the ultimate addition to your collection in traditional oak, mahogany, walnut or rosewood. Alongside our ever growing antique collection are our modern desks and writing tables, from recent movements such as the mid-century modern era and the rigid style of the brutalist movement, which grew out of the early-20th century modernist style. Our collection is ever changing and evolving, purchase in confidence from our expert dealers who are internationally recognised.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/desks-and-writing-tables.jpg',
      'level': '2',
      'categoryId': '32',
      'parentId': '3'
    },
    {
      'title': 'Dressers Dresser Bases and Cupboards',
      'slug': 'dressers-dresser-bases-and-cupboards',
      'description': '<p>A collection of antique dressers, dresser bases and cupboards from the UK’s most reputable dealers. Shop unique and beautifully crafted pieces, including beautifully carved early oak from the 17th century to the futuristic chrome designs of world famous 20th century designers. Included in our selection from our hand picked dealers are antique Welsh dressers, 19th century mahogany bases and Chinese red lacquer cupboards. All our items come from the collections of trusted dealers so you can browse and purchase with confidence.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '2',
      'categoryId': '33',
      'parentId': '3'
    },
    {
      'title': 'Garden and Architectural',
      'slug': 'garden-and-architectural',
      'description': '<p>Our collection of antique garden and architectural pieces, includes decorative garden ornaments from the 17th century and intricate delicate statues in stone and lead. Included in our Garden and & Architectural category are fountains, troughs, park benches and even designer doors! Many pieces are steeped in hundreds of years of history or lovingly created by world famous designers and artists. Our ever-changing collection features pieces from the country’s top dealers, all of whom are experts in their field. Find the perfect statement piece for your garden or home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '2',
      'categoryId': '34',
      'parentId': '3'
    },
    {
      'title': 'Lighting',
      'slug': 'lighting',
      'description': '<p>Our lighting selection spans the last four centuries and includes antique candelabras to handcrafted designers of the 21st century. Shop our extensive collection of lighting which includes candelabras, candlesticks, table lamps and wall sconces. Our designer and antique lighting collection includes pieces from all over the world, in a range of materials including silver, bronze, porcelain and brass. Whatever your style or taste, we’re confident you’ll find the perfect one of a kind addition for your interior on 2Covet.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/lighting.jpg',
      'level': '2',
      'categoryId': '35',
      'parentId': '3'
    },
    {
      'title': 'Mirrors',
      'slug': 'mirrors',
      'description': '<p>Discover our collection of decorative antique and modern mirrors, including floor length and over-mantle mirrors. Choose from a selection of beautifully crafted pieces including a large Giltwood and Vernis Martin mirror by Louis Majorelle, housed in the Dutch Royal Palace of Het Loo, 1888. Our collection of mirrors encompasses incredible antique designs but also modern masterpieces from the likes of Michel Zadounaisky. Mirrors can add another dimension to any home from sculpted and patinated steel mirrors to classic carved antique designs. You can shop with confidence as all our mirrors come from internationally recognised dealers who are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/mirrors.jpg',
      'level': '2',
      'categoryId': '36',
      'parentId': '3'
    },
    {
      'title': 'Miscellaneous',
      'slug': 'miscellaneous',
      'description': '<p>Discover a range of miscellaneous objects including cannons, canterburys, globes, screens, shelving, whatnots, wine coolers and cellarets. Our Miscellaneous category is full of antique objects and modern pieces for your home. We work with internationally recognised dealers and experts so you can browse and purchase antiques for your collection on confidence.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '2',
      'categoryId': '37',
      'parentId': '3'
    },
    {
      'title': 'Rugs Carpets and Textiles',
      'slug': 'rugs-carpets-and-textiles',
      'description': '<p>Discover our incredible range of antique Rugs, Carpets and textiles from the highly patterned to the elegantly simple. Our selection includes a vast selection of high quality hand-mmodern, contemporary and antique rugs and textiles. With a huge passion and knowledge our carefully selected dealers offer an extensive beautiful collection of rugs and textiles from Persia, India, Japan, China and the Caucasus, ranging in time from the 18th century through to the present day. Shop with confidence, as you can be safe in the knowledge that each of our dealers are well established and highly respected within the industry and carefully select the highest quality unique rugs and textiles to add to their ever growing inventory.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/rugs-carpets-textiles.jpg',
      'level': '2',
      'categoryId': '38',
      'parentId': '3'
    },
    {
      'title': 'Seating',
      'slug': 'seating',
      'description': '<p>Immerse yourself in the widest range of antique seating, from armchairs and settees to benches and stools. The history of seating has developed from as far back as the Egyptian era and each item selected by our dealers here at 2Covet has a rich dialogue, so you can expect to find yourself sat on a piece of history. Simple benches started to appear in Medieval times, whilst the stool commonly appeared in farmhouses, kitchens and public houses. Armchair design was spearheaded by the French in the 18th century, whilst comfort became a priority in the Victorian era as upholstery became increasingly popular. Whichever type of chair you are searching for, you can be sure that here at 2Covet, the quality and history will be second to none.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/seating.jpg',
      'level': '2',
      'categoryId': '39',
      'parentId': '3'
    },
    {
      'title': 'Tables',
      'slug': 'tables',
      'description': '<p>Explore our range of tables, from the beautifully simple to the dramatically decorative. Within our selection here at 2Covet we have a number of larger tables including dining tables, writing tables, bureaux and games tables. We also have an extensive collection of smaller tables including centre tables, coffee tables, side tables, lamp tables and writing tables. Within this vast catalogue there really is a table to fit any type of interior or style. Tables have always played a pinnacle part within interior design, with such a vast range of functionalities, tables can be categorised by their place within the home, for instance the dining room table representing the social hub of the home whereas the writing table represents creativity and learning. Our tables range from the 16th century to modern design of the 21st century. With an experienced and enthusiastic group of dealers you can be confident in your purchase.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/tables.jpg',
      'level': '2',
      'categoryId': '41',
      'parentId': '3'
    },
    {
      'title': 'Wardrobes and Linen Presses',
      'slug': 'wardrobes-and-linen-presses',
      'description': '<p>Making clothes was a costly and time consuming process so wardrobes and linen presses were used to help store and preserve the quality of our clothes. In fact the word ‘wardrobe’ derives from the words warder, meaning “to protect”, and robe, meaning a “piece of clothing,”. Although wardrobes are now a common place within homes, linen presses are no longer usually used for their functionality but more as a decorative antique.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/wardrobes-linen-presses.jpg',
      'level': '2',
      'categoryId': '42',
      'parentId': '3'
    },
    {
      'title': 'Beds',
      'slug': 'beds',
      'description': '<p>View our collection of antique and vintage beds for sale. Shop our one of a kind ornately carved antique beds made by renowned makers such as Gillow & Co. Our well established dealers also specialise in art deco, mid century and modern beds and bed frames. All of our vetted dealers are extremely knowledgeable and you can communicate directly with the experts, who can help source one of a kind beds, daybeds and bedframes for your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/beds.jpg',
      'level': '3',
      'categoryId': '43',
      'parentId': '25'
    },
    {
      'title': 'Daybeds',
      'slug': 'daybeds',
      'description': '<p>We have a large selection of one of a kind daybeds from vetted and specialist dealers. Our well established dealers have hundreds of years of experience and also specialise in art deco, mid century and modern daybeds. Shop our collection knowing there are no commissions or inflated prices to pay. You can directly communicate with the experts who can help source one of a kind beds, daybeds and bedframes for your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '44',
      'parentId': '25'
    },
    {
      'title': 'Boxes',
      'slug': 'boxes',
      'description': '<p>Our incredible collection of antique and decorative boxes. Shop our collections of antique boxes including jewellery boxes, games boxes, writing boxes, snuff boxes and unique boxes, ranging from the 16th century to exceptional modern day design. Shop collectors boxes and deal directly with world renowned expert dealers. Buy one of a kind gifts and unique items.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/boxes.jpg',
      'level': '3',
      'categoryId': '45',
      'parentId': '26'
    },
    {
      'title': 'Caddies',
      'slug': 'caddies',
      'description': '<p>Our selection of highly collectible antique, vintage, mid-century and modern tea caddies from the world\'s best dealers. Highly collectible tea caddies from as early as the 18th century to modern day design. Shop our collection of hundreds of antique tea caddies, boxes and accessories. Exquisite one of a kind tea caddies to add to your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/boxes-and-caddies.jpg',
      'level': '3',
      'categoryId': '46',
      'parentId': '26'
    },
    {
      'title': 'Tantalus',
      'slug': 'tantalus',
      'description': '<p>A tantalus is an open cabinet or case usually constructed from wood or metal, containing between 2 and 4 decanters. The tantalus contains a lock and key, so that the decanter and its contents could be seen but not touched by unauthorised hands. First appearing in the mid 19th century, it is understood in 1881 that a design for a wooden tantalus was patented by Mr John Betjemann.</p><p>The name Tantalus originates from the figure in Greek mythology, who committed crimes against the Gods which resulted in his punishment in the underworld. Zeus was so angered by Tantalus’ actions that he administered one of his most severe punishments. Tantalus was placed below a tree that dangled succulent fruit in front of him, but that he could never quite reach; he was also surrounded by a pool of water that parted whenever he went to drink. This resulted in an insatiable thirst and hunger that could never be met. This is where the name Tantalus for the locked away decanters came from, as the drinks are in view but out of reach without the key.</p>',
      'categoryId': '172',
      'parentId': '26',
      'level': '3',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/tantalus.jpg'
    },
    {
      'title': 'Tea Caddies',
      'slug': 'tea-caddies',
      'description': '<p>Our collection of Tea Caddies is amassed from world renowned dealers. When first introduced to Europe, tea was expensive and kept in decorative containers in the drawing-room. Our collection of antique tea caddies features beautiful pieces from the 17th century in a range of styles and materials, including wood, porcelain and mother of pearl. Working with the very best dealers in the country, you can purchase with confidence in the knowledge that you are browsing pieces selected by the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/boxes-and-caddies.jpg',
      'level': '3',
      'categoryId': '47',
      'parentId': '26'
    },
    {
      'title': 'Bookcases',
      'slug': 'bookcases',
      'description': '<p>A unique selection of Antique, Modern and Designer bookcases. Our collection features one of a kind bookcases of the Napoleon III Period and imposing breakfront bookcases from the Regency and Victorian eras. Discover rare museum quality bookcases crafted by famous names of forgotten times. Deal directly with the experts who can assist you with a wealth of knowledge.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/bookcases.jpg',
      'level': '3',
      'categoryId': '48',
      'parentId': '27'
    },
    {
      'title': 'Cabinets',
      'slug': 'cabinets',
      'description': '<p>A unique selection of Antique, Modern and designer Cabinets. Our collection features one of a kind cabinets of the Louis XV Period and matching ornately gilded cabinets from the Regency and Victorian eras. Discover rare museum quality cabinets crafted by famous names such as François Linke. Deal directly with the experts who can assist you with a wealth of knowledge.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/cabinets.jpg',
      'level': '3',
      'categoryId': '49',
      'parentId': '27'
    },
    {
      'title': 'Buffets',
      'slug': 'buffets',
      'description': '<p>Browse our selection of buffets from the highly decorative to the beautifully functional. Add storage space and style to any dining room with our buffets, which can be used to serve food, display serving dishes and for storage. As well as the dining room, which is the traditional place of the buffet, this versatile piece can be used throughout the house to maximise storage. Shop our collection of Buffets and enrich your home in style. By shopping on 2Covet you are dealing only with experienced well established dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/buffets-commodes-sideboards.jpg',
      'level': '3',
      'categoryId': '95',
      'parentId': '40'
    },
    {
      'title': 'Commodes',
      'slug': 'commodes',
      'description': '<p>Discover our range of commodes from renowned, highly experienced dealers. Richly decorated and highly detailed, commodes are the perfect piece of furniture to add interest and storage to any room. As well as being attractive the commode is perfect for keeping any rooms clean and clutter free. Our incredible selection includes; satinwood, mahogany, oak and walnut commodes spanning from the 17th - 21st century. Many of the commodes featured on 2Covet are of museum quality and offered by world famous dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/commodes.jpg',
      'level': '3',
      'categoryId': '96',
      'parentId': '40'
    },
    {
      'title': 'Sideboards',
      'slug': 'sideboards',
      'description': '<p>Explore our carefully selected range of sideboards. Much like the buffet, the sideboard was traditionally used within the dining room but due to its versatile nature, this incredible piece of furniture can be used anywhere within the home. Perfect for displaying items such as plates, photographs or personal keepsakes. Sideboards can also be reinvigorated into extra bedroom storage or a bathroom vanity. The range on offer here at 2Covet includes both contemporary and antique sideboards which have the versatility to suit any decor.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/buffets-commodes-sideboards.jpg',
      'level': '3',
      'categoryId': '97',
      'parentId': '40'
    },
    {
      'title': 'Chests',
      'slug': 'chests',
      'description': '<p>Our selection of Chests from the country’s top antique furniture dealers. The ultimate combination of style and storage, chests make the perfect addition to your home’s interior with their timeless elegance. Discover beautifully crafted pieces including commode chests, coffers, military chests and more in a range of materials including oak, rosewood, teak and leather dating as far back as the early 17th century.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/chests.jpg',
      'level': '3',
      'categoryId': '50',
      'parentId': '29'
    },
    {
      'title': 'Trunks',
      'slug': 'trunks',
      'description': '<p>Find the perfect trunk or storage chest to complement your home. Our collection of Trunks comes from the country’s top dealers. With world renowned dealers who specialise in famous names such as Louis Vuitton. Whether you’re looking for a statement piece or something that complements the rest of your décor, our selection includes rare finds dating back to the 17th century. Covering a range of periods, styles and materials, you can browse and purchase in confidence in the knowledge that you are dealing direct with the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/trunks.jpg',
      'level': '3',
      'categoryId': '51',
      'parentId': '29'
    },
    {
      'title': 'Carvings',
      'slug': 'carvings',
      'description': '<p>Cultures across the globe have used wood carving as an art form for thousands of years, dating all the way back to the Stone Age. As a readily available material with varied qualities and textures, wood has been used by artists and sculptors to metamorphose natural elements into abstract structures. In contrast to this, the natural forms of wood itself are also the basis of many pieces and designs that we come to call antiques today.</p><p>Back in the 15th century, carvings of the figure of Madonna reached their peak, with the enthroned Madonna becoming typical of the middle ages and iconography known as the throne of wisdom, translated to Latin as sedes sapieniae. Carvings of this kind were often carried in processions during the Middles Ages, as the Madonna was seen as as a symbol of survival and jurisdiction amongst periods of conflict, hunger and disease.</p><p>Centuries later across the pond in Central Europe, Black Forest carvings grew in popularity and remain so to this day. The Black Forest refers to the surrounding forests of Brienz, Switzerland, which produced woods such as walnut and linden wood used in these incredible carvings. Carvings of the Black Forest are easily identifiable through their extravagant but realistic designs and common themes such as animals climbing trees or playing instruments, birds and intertwined branches.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '54',
      'parentId': '31'
    },
    {
      'title': 'Vases',
      'slug': 'vases',
      'description': '<p>Our extensive collection of vases is ever growing and encompasses a wide selection of styles, shapes and periods. Including coveted chinese and cantonese porcelain antiques, French art deco bronze urns and shining silver vases from modern silversmiths, our collection features unique pieces for every taste and décor. Shop with confidence from the country’s top dealers, find the perfect decorative vase to add to your collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/vases.jpg',
      'level': '3',
      'categoryId': '53',
      'parentId': '31'
    },
    {
      'title': 'Desks',
      'slug': 'desks',
      'description': '<p>Discover our selection of antique and modern desks, including a range of periods from early Georgian, through to the Victorian and Edwardian eras. Our collection features incredible exhibition quality desks from famous makers and designers such as Holland & Sons and breathtaking craftsmanship from the likes of Thomas Chippendale. Suitable for a variety of tastes and budgets, direct from the country’s most experienced dealers. From 18th century masters to 20th century special editions you can shop with confidence from our extensive collection and deal directly with experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/desks.jpg',
      'level': '3',
      'categoryId': '55',
      'parentId': '32'
    },
    {
      'title': 'Writing Tables',
      'slug': 'writing-tables',
      'description': '<p>Discover our collection of ornate and decorative writing tables and bureaux from the best dealers in the country. Included in our collection are one of a kind rare Davenport designs, Bureaux Plats in the Louis XV manner by world renowned names such as Henri Picard. Writing tables from the 19th century which are constructed in the finest woods and decorated with beautiful scenes. Alongside our finest antique writing tables we also have modern glass and metal tables from 20th century designers. Shop our ever growing eclectic selection and find the perfect writing table to add your home. Browse and purchase in confidence from our wide selection of styles from around the world to find the perfect addition for your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '56',
      'parentId': '32'
    },
    {
      'title': 'Cupboards',
      'slug': 'cupboards',
      'description': '<p>Discover something special in our selection of cupboards and cabinets from the last 400 years. Both functional and beautiful, our collection showcases a variety of styles and sizes, including elizabethan oak and chinese red lacquer. Our collection of antique and designer cupboards is full of world famous names and pieces of the highest quality. Browse and purchase in confidence from the country’s top dealers, all of whom are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '57',
      'parentId': '33'
    },
    {
      'title': 'Dressers',
      'slug': 'dressers',
      'description': '<p>Our collection of antique dressers includes a variety of styles and wood types, including elegant French dressers, large early oak dressers and beautiful Victorian designs. Our dressers ranging from the 17th century to the 20th century. Whether you’re looking for a statement piece or something to complete an already beautiful room, we are confident you’ll find something you love from one of our reputable world famous dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '58',
      'parentId': '33'
    },
    {
      'title': 'Dresser Bases',
      'slug': 'dresser-bases',
      'description': '<p>Find the perfect antique dresser base in a variety of colours and styles, including oak, mahogany, pine, rosewood, gilded or lacquered. Our collection includes pieces from the country’s most reputable dealers, so you can browse and purchase with confidence. Whether you’re looking for pieces from the 18th, 19th or 20th century, we are confident you’ll find something you love.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '59',
      'parentId': '33'
    },
    {
      'title': 'Candelabras and Chandeliers',
      'slug': 'candelabras-and-chandeliers',
      'description': '<p>Our decorative and ornate candelabras are the perfect addition for any collector’s home. Including fine antique silver, bronze and brass from 17th, 18th and 19th century Europe. Our collection consists of fine candelabras from country’s top dealers who source the very best pieces and are world renowned and recognised. Browse and purchase with confidence in the knowledge that you are dealing direct with experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/candelabras-chandeliers.jpg',
      'level': '3',
      'categoryId': '60',
      'parentId': '35'
    },
    {
      'title': 'Candlesticks',
      'slug': 'candlesticks',
      'description': '<p>Discover intricate and ornate antique candlesticks, dating from the 17th century and collected by the country’s top dealers. Candlesticks are the perfect decorative addition to your home, our collection includes fine silver, porcelain and brass candlesticks. Browse and purchase with confidence in the knowledge that you are dealing direct with experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver.jpg',
      'level': '3',
      'categoryId': '61',
      'parentId': '35'
    },
    {
      'title': 'Floor Lamps',
      'slug': 'floor-lamps',
      'description': '<p>Transition from afternoon to evening in style with our collection of antique and designer floor lamps. Add ambience and warmth with a unique and decorative piece from one of our world renowned reputable dealers. Discover a wide range of lamps from different historical periods including converted victorian oil lamps and art nouveau polished brass lamps to modern 21st century world famous designers. Browse and purchase with confidence in the knowledge that you are dealing direct with experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/floor-lamps.jpg',
      'level': '3',
      'categoryId': '62',
      'parentId': '35'
    },
    {
      'title': 'Girandole',
      'slug': 'girandole',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '63',
      'parentId': '35'
    },
    {
      'title': 'Other Lighting',
      'slug': 'other-lighting',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/other-lighting.jpg',
      'level': '3',
      'categoryId': '64',
      'parentId': '35'
    },
    {
      'title': 'Table Lamps',
      'slug': 'table-lamps',
      'description': '<p>Bring warmth to your home with our selection of antique, vintage and designer table lamps. Our Table Lamps include antique 19th century Cantonese ovoid table lamps and rare vintage 1970s table lamps. Our collection includes monumental metal and chrome statement table lamps from world renowned 20th century designers. Browse and purchase in confidence in the knowledge that you are dealing with the country’s top dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/table-lamps.jpg',
      'level': '3',
      'categoryId': '65',
      'parentId': '35'
    },
    {
      'title': 'Torchere',
      'slug': 'torchere',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '66',
      'parentId': '35'
    },
    {
      'title': 'Wall Sconces',
      'slug': 'wall-sconces',
      'description': '<p>Our collection of designer and antique wall sconces includes ornate 19th century French gilded designs to world famous contemporary designers such as Rougier. All of our wall sconces come from the most reputable dealers across the country. Our collection features a variety of wall lighting and sconces for all styles and tastes dating from as early as the 18th century, including ornate metal, brass, silver and glass. Shop with confidence from experts in fine antiques.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '67',
      'parentId': '35'
    },
    {
      'title': 'Convex Mirrors',
      'slug': 'convex-mirrors',
      'description': '<p>Derived from Europe alongside the invention of glass-blowing during the Medieval-Renaissance period, the convex mirror was originally formed from a small glass bulb coated with reflective tin or lead. Highly ornamental editions of convex mirror became popular in the 18th century, often embellishing the walls of wealthy Europeans. In particular, the French and English are renowned for the design and mastery of Empire and Regency convex mirrors respectively, which were delicate, decorative and decadent.</p>\n<p>Practically speaking, convex mirrors were useful for butlers in affluent private households, in order to keep a discrete eye on the dining table without looking directly at the principal and guests. Moreover, from the 15th century onwards, bankers and goldsmiths frequently used convex mirrors to monitor the room in a singular glance, whilst surveilling potentially underhand customers. Stylistically, the curved reflective surface displays a virtual representation of reality whilst refracting beams of light around the interiors.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '68',
      'parentId': '36'
    },
    {
      'title': 'Floor Length Mirrors',
      'slug': 'floor-length-mirrors',
      'description': '<p>Discover our collection of floor length mirrors, including elegantly adorned and exquisitely detailed designs ranging from the 18th century to the early 21st century. Our collection includes beautiful Venetian glass, ormolu and carved giltwood from Italy, France and England alongside American mid-century designers. Browse and purchase with confidence from the country’s most reputable and expert dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '69',
      'parentId': '36'
    },
    {
      'title': 'Over Mantel Mirrors',
      'slug': 'over-mantel-mirrors',
      'description': '<p>Our over-mantle mirrors can make a striking addition to a room. Our extensive collection includes a variety of shapes and sizes dating from extraordinary Regency over-mantle mirrors and sleek modern designs. Many of our over-mantle mirrors date back hundreds of years and are beautifully carved in giltwood and brass. Shop with confidence from the country’s top dealers of fine antiques.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '70',
      'parentId': '36'
    },
    {
      'title': 'Cannon',
      'slug': 'cannon',
      'description': '<p>Shop our selection of antique cannon. Our collection includes English Naval cannon from the 19th century and European cannon steeped in history. An incredible way to add to a nautical collection. Shop with confidence in the knowledge that our dealers are world renowned experts in maritime and nautical antiques and objects.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/cannon.jpg',
      'level': '3',
      'categoryId': '71',
      'parentId': '37'
    },
    {
      'title': 'Canterburys',
      'slug': 'canterburys',
      'description': '<p>Originally designed for holding sheet music, our collection of decorative antique canterburys date back from the 18th century, exceptional in design and made from rich mahogany, rosewood and walnut. Our later canterburys include polished brass and ornate metal. Store your newspapers and magazines in style with an antique canterbury from our trusted dealers in fine antiques.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '72',
      'parentId': '37'
    },
    {
      'title': 'Desk Items',
      'slug': 'desk-items',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '81',
      'parentId': '37'
    },
    {
      'title': 'Gesso',
      'slug': 'gesso',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '73',
      'parentId': '37'
    },
    {
      'title': 'Obelisks',
      'slug': 'obelisks',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '75',
      'parentId': '37'
    },
    {
      'title': 'Pedestals and Tazzas',
      'slug': 'pedestals-and-tazzas',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '76',
      'parentId': '37'
    },
    {
      'title': 'Screens',
      'slug': 'screens',
      'description': '<p>Shop our collection of room screens. Add elegance to any luxury interior with our range of antique and contemporary screens and room dividers. Originating from ancient China, these decorative and functional pieces became popular in Europe during the 17th and 18th centuries. Our collection features a variety of styles from across the centuries, discovered by our most reputable dealers in fine arts, so you can browse and purchase in complete confidence.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '77',
      'parentId': '37'
    },
    {
      'title': 'Wall Shelving',
      'slug': 'wall-shelving',
      'description': '<p>Discover our collection of antique shelving including Victorian shelves, Regency wall units and art deco bronze shelving. Browse and purchase in confidence from our internationally recognised dealers who are experts in their field. Including decorative and functional pieces from the 18th, 19th and 20th century, it’s easy to find the perfect piece for your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '78',
      'parentId': '37'
    },
    {
      'title': 'Whatnots',
      'slug': 'whatnots',
      'description': '<p>Browse our selection of whatnot stands, popular in the 19th century to display a variety of objects, curiosities, books and papers. Our collections included pieces crafted from mahogany, walnut and rosewood. Shop with confidence from the country’s top dealers who are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '79',
      'parentId': '37'
    },
    {
      'title': 'Wine Coolers Cellarettes',
      'slug': 'wine-coolers-cellarettes',
      'description': '<p>An essential for any wine collector, our collection of antique wine coolers and cellarettes date back to the regency period are crafted from the finest mahogany wood, terracotta and even silver. Browse and purchase with confidence from the country’s most reputable and expert dealers on 2Covet.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '80',
      'parentId': '37'
    },
    {
      'title': 'Carpets',
      'slug': 'carpets',
      'description': '<p>Discover our vast selection of carpets; there is no better way to frame a room than an antique carpet. Carpets have the ability to bring a room together. The luxury of an antique Persian carpet to the comfort of a natural wool carpet. Feel secure in the knowledge that you are purchasing from internationally recognised dealers with a plethora of experience and industry knowledge.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/carpet.jpg',
      'level': '3',
      'categoryId': '83',
      'parentId': '38'
    },
    {
      'title': 'Rugs',
      'slug': 'rugs',
      'description': '<p>Discover our incredible range of antique rugs from the highly patterned to the elegantly simple. Our selection includes a vast selection of high quality handmade modern, contemporary and antique rugs. With a huge passion and knowledge our carefully selected dealers offer an extensive beautiful collection of rugs and textiles from Persia, India, China and the Caucasus, ranging in time from the 18th century through to the present day. Shop with confidence, as you can be safe in the knowledge that each of our dealers are well established and highly respected within the industry and carefully select the highest quality unique rugs to add to their ever growing inventory.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/rugs.jpg',
      'level': '3',
      'categoryId': '84',
      'parentId': '38'
    },
    {
      'title': 'Tapestries',
      'slug': 'tapestries',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/taperstries.jpg',
      'level': '3',
      'categoryId': '85',
      'parentId': '38'
    },
    {
      'title': 'Textiles',
      'slug': 'textiles',
      'description': '<p>Discover our incredible range of antique textiles from the highly patterned to the elegantly simple. Our selection includes a vast selection of high quality hand-made modern, contemporary and antique textiles from around the world. With a huge passion and knowledge our carefully selected dealers offer an extensive beautiful collection of textiles from all corners of the globe. Ranging in time from the 18th century through to the present day. Shop with confidence, as you can be safe in the knowledge that each of our dealers are well established and highly respected within the industry and carefully select the highest quality unique textiles to add to their ever growing inventory.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/textiles.jpg',
      'level': '3',
      'categoryId': '86',
      'parentId': '38'
    },
    {
      'title': 'Armchairs',
      'slug': 'armchairs',
      'description': '<p>The curved back, delicate lines and supportive sides of the classic armchair make it an exquisite combination of design and purpose. The stunning range of armchairs are hand selected by our specialist dealers, so you can rest assured you are looking at beautiful, quality pieces. Armchairs can come sumptuously upholstered, padded or with coil springs that provide comfort and support. Tirelessly carved from wood, commonly oak, pine and walnut, which includes stylised shaping and sturdy craftsmanship. Typically led by the French in the 18th century, the armchair is both decorative and versatile and has evolved over the centuries to fit into any modern day home. Add one of 2Covet’s magnificent armchairs to your collection and recline into luxury and comfort.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/armchairs.jpg',
      'level': '3',
      'categoryId': '87',
      'parentId': '39'
    },
    {
      'title': 'Benches',
      'slug': 'benches',
      'description': '<p>Beautiful benches made from wood, metal, marble or stone, here at 2Covet we can ensure you will not be disappointed with the selection of benches carefully chosen by our specialist dealers. Our collection encompasses both antique and contemporary benches. One of the first types of seating to be developed, the bench has evolved from basic and purpose built to decorative and design led, but still with a strong focus on mastery through the centuries. Dating as far back as Egyptian times, the bench stayed an integral aspect of seating through the ages, as the benches we see here on 2Covet include inspiration from the medieval and gothic eras, and were heavily used for religious purposes. Nowadays, the bench can be a purposeful, historical and decorative addition to any home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '88',
      'parentId': '39'
    },
    {
      'title': 'Chairs',
      'slug': 'chairs',
      'description': '<p>There are a wide range of chairs available here at 2Covet from Edwardian to Victorian, Georgian to Elizabethan and, of course, exceptional 20th century design. The main materials traditionally used are a range of woods, ranging from oak and pine to walnut and mahogany. Modern designers have favoured the use of metals such as chrome and futuristic lucite. Our chairs also feature finishes that include polished wood, decadent upholstery or buttoned leather with padding, to provide an extra level of luxury and comfort, the chairs available are here are carefully selected by our internationally recognised dealers, so you can rest assure the quality and history will be a welcome addition to your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/chairs.jpg',
      'level': '3',
      'categoryId': '89',
      'parentId': '39'
    },
    {
      'title': 'Dining Chairs',
      'slug': 'dining-chairs',
      'description': '<p>The right collection of dining chairs can elevate either a room, or an occasion, to new heights. If you are looking for a classic set with a story to be told, or an eye catching piece of design for guests to marvel, 2Covet will have something to suit. We have a wide selection of dining chairs, both antique and contemporary, all of which are individually selected by our team of dedicated specialists. Whether chosen for the quality of their materials, their striking design, luxurious comfort or a combination of all three, you can be sure to find something that will catch your eye. Our dining chairs include designs from Elizabethan to Georgian, Art-Nouveau to Mid-century. Our collection is a tour through the ages, spanning centuries - meaning you can be sure to find something to suit any collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '90',
      'parentId': '39'
    },
    {
      'title': 'Library Chairs',
      'slug': 'library-chairs',
      'description': '<p>Often made in pairs, library chairs have been designed and crafted throughout a range of eras, from 19th century Gothic, Victorian, Edwardian and Regency. Dark woods, sumptuous fabrics and heavy leathers provide the perfect accompaniment to any library or reading corner. The craftsmanship often includes decadent carving and expertly curved wood. Historically, the library chair was created for the more affluent houses, in order for two people to study and read together in the comfort of their own home. Nowadays, these chairs are more accessible and perfect for reading nooks and studies. At 2Covet, you can shop with confidence as you peruse the items specially selected by our internationally recognised dealers who are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '91',
      'parentId': '39'
    },
    {
      'title': 'Settes and Sofas',
      'slug': 'settes-and-sofas',
      'description': '<p>The embodiment of luxury and comfort, a settee or sofa can often be the epicentre of a space, and the perfect settee or sofa can bring a new dimension to any room. Our Settees and Sofas include timeless classics crafted in leather and mahogany, or stand out pieces with vivid colours and intricate patterning. Any and all can be found in 2Covet’s hand-picked selection of settees and sofas. The right piece can meet any needs, whether you’re looking to add a subtle tone of sophistication to a room, or grab your guest’s attention with a standout piece that can’t be missed. Browse through our collection and find whatever you’re looking for. Chosen for their quality, by our dedicated team of specialists dealers, you can be sure you’re in safe hands with any new addition.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '92',
      'parentId': '39'
    },
    {
      'title': 'Stools',
      'slug': 'stools',
      'description': '<p>As one of the earliest forms of seating, used by the Egyptians and in Medieval times, stools have stood the test of time and are still widely utilised in the modern home. Whilst smaller and arguably more practical, stools widely reflect the design of chairs from the same era. The Georgian design resembles the decoratively carved wooden legs and soft upholstered seat present in chairs of that era. This was also adapted to suit the purpose of a piano stool and a dressing table stool, with the seat of the stool spinning to allow for movement and adjustable height for maximum ease of performance and comfort. Popular designs available here at 2Covet range from Edwardian, French 19th century and Edwardian and here at 2Covet we hope you can find practical and purposeful stools that are hand selected by our specialist dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '93',
      'parentId': '39'
    },
    {
      'title': 'Wing Chairs',
      'slug': 'wing-chairs',
      'description': '<p>Originally designed with wooden wings and a padded seat, the winged chair has now developed into luxury and comfort, with the upholstery now encompassing the whole chair to provide support and cosiness. A true investment piece that provides both homely comfort and timeless style, the selection of wingback chairs at 2Covet have been curated by our specialist dealers to provide you with a diverse choice throughout the eras. Taking inspiration from the French design and luxury, wing back chairs rose in popularity in colonial America and England, where buttons and sumptuous fabrics were incorporated into the composition. Nordic countries followed suit in the 18th and 19th century and adapted the designs into a somewhat Scandinavian style. With the history of the wing chair ever growing, our dealers are internationally recognised in their field and will be able to guide you to the perfect chair for your home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '94',
      'parentId': '39'
    },
    {
      'title': 'Centre Tables',
      'slug': 'centre-tables',
      'description': '<p>One of the most common types of tables is the centre table. Designed to draw your eye to the centre of the room, the centre table sits proud, often decorated with silver or flowers to further heighten the status of the table. Centre tables enhance the look of a room and also serve a utilitarian function. Have peace of mind whilst shopping on 2Covet as all our dealers have years of industry knowledge and experience. Find the perfect centre table for your home or collections with pieces ranging from 19th century antique painted tables to 1960’s bronze patinated mirror centre tables.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/centre-tables.jpg',
      'level': '3',
      'categoryId': '98',
      'parentId': '41'
    },
    {
      'title': 'Coffee Tables',
      'slug': 'coffee-tables',
      'description': '<p>Traditionally found within the sitting room, the coffee table is an essential piece of furniture situated in front of the seating area. Our collection includes legendary contemporary designers which are highly decorative, from 1960’s brutalist gilded aluminium coffee tables to mid-century Kittinger coffee tables. Whether you are searching for a modern contemporary feel or something more traditional you can shop 2Covet knowing that you are dealing only with experienced well established dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '99',
      'parentId': '41'
    },
    {
      'title': 'Console Tables',
      'slug': 'console-tables',
      'description': '<p>At 2Covet we have an exceptional collection of console tables from centuries past. With Italian and French origins, the console table was a design staple in the Palace of Versailles and residences of Louis XIV. The name originated from the original designs which included a curved S bracket which typically fixed it to the wall. In 17th century Italy, the console table was considered a symbol of fashion as it represented a move towards ‘display furniture’. This was reflected in the resplendent designs which often involved intricate carvings and expensive materials.</p><p>Louis XIV popularised the console table further, and adapted the design to include two inward curving legs, often made in pairs and placed underneath matching mirrors. The console table soon became an emblem of Rococo style, but as designs spread to England, the classical revival brought new styles in the late 18th century, which included painted mahogany and satinwood, with decorations in light colours.</p><p>Throughout the centuries the console table has evolved from a primarily decorative piece to combine a level of practicality, often including four legs to allow them to stand without the support of a wall. Shop the incredible collection of console tables here on 2Covet, brought to you by the specialist 2Covet Dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/console-tables.jpg',
      'categoryId': '162',
      'parentId': '41',
      'level': '3'
    },
    {
      'title': 'Dining Tables',
      'slug': 'dining-tables',
      'description': '<p>Explore our section of dining tables. For many the dinning table has a nostalgic feel, as it is the social hub of the house and where celebrations and feasts take place. Such an important role within the house deserves a special piece. Our selection of dining tables includes; early 16th Century oak, Georgian mahogany, Regency rosewood and 1970’s chrome and glass. You can be safe in the knowledge that all pieces have been selected to feature on 2covert for their interesting history and beautiful exterior.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '100',
      'parentId': '41'
    },
    {
      'title': 'Games Tables',
      'slug': 'games-tables',
      'description': '<p>There is nothing that brings a collection of people together like a game. We have an extensive collection of games tables, find the perfect addition to your collection with pieces ranging from George IV games tables, Kingwood card tables by G. Durandand and Regency Period Yew-wood work or games tables. Our games tables can be functional or simply an interesting piece of history to dress up a study or leisure room.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '101',
      'parentId': '41'
    },
    {
      'title': 'Other Tables',
      'slug': 'other-tables',
      'description': '<p>Tables have many functions in the home and therefore they vary in style and purpose. Writing tables and bureaux are often used within a study whereas a console table would be placed in a room either side of the fire place or on the end wall. Within our collection we have unique and show stopping tables including a Hexagonal walnut display table attributed to Holland and Sons and an antique ladies dressing table by Betjemann.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '102',
      'parentId': '41'
    },
    {
      'title': 'Side Tables',
      'slug': 'side-tables',
      'description': '<p>Side tables are usually small tables placed at the side of a chair or sofa. They are utilised for their convenience but by no means are they uninteresting. Side tables are stunning pieces of furniture in themselves. Typically, small household objects are placed on the tables or objects to display. Much like a centre table, side tables are a perfect exhibition space in the home. Our collection is extensive, with many of our tables being made with luxury materials including marble, mahogany and amboyna.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '103',
      'parentId': '41'
    },
    {
      'title': 'Linen Presses',
      'slug': 'linen-presses',
      'description': '<p>Explore our selection of linen presses, made predominantly in the 17th, 18th and 19th century. The linen presses were made to store sheets, clothing and other types of textiles and would have been made of pine, oak, mahogany or walnut. Linen presses are no longer usually used for their functionality but it is a beautifully decorative example of antique furniture and would make a charming addition to anyone’s home.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '104',
      'parentId': '42'
    },
    {
      'title': 'Wardrobes',
      'slug': 'wardrobes',
      'description': '<p>Explore our carefully selected range of wardrobes, a staple within the bedroom and a very useful piece of furniture indeed. Wardrobes are a grand piece of furniture; due to their sheer size they are usually the focus of a bedroom. Used to store and preserve clothes they have become common place within the home. Made from sturdy mahogany and oak, they stand the test of time. Buy with confidence knowing you are dealing directly with well established dealers who have enthusiasm and passion within their specialisms.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/wardrobes.jpg',
      'level': '3',
      'categoryId': '105',
      'parentId': '42'
    },
    {
      'title': 'Jewellery',
      'slug': 'jewellery',
      'description': '<p>Jewellery has always been highly coveted, especially antique jewellery due to its superior design and craftsmanship. Discover rare and unique pieces from our collection, including bracelets, brooches, cufflinks, earrings and rings, some of our unique pieces date back hundreds of years. From gorgeous Georgian to mid-century masterpieces, we have something for all tastes. Our contemporary jewellery is not to be forgotten as we have beautiful pieces from world famous names such as Cartier and Tiffany. Shop with confidence in the knowledge that all our items are selected from the country’s very best jewellery dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/jewellery.jpg',
      'level': '2',
      'categoryId': '106',
      'parentId': '4'
    },
    {
      'title': 'Necklace and Pendants',
      'slug': 'necklace-and-pendants',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '2',
      'categoryId': '148',
      'parentId': '4'
    },
    {
      'title': 'Watches',
      'slug': 'watches',
      'description': '<p>Our collection of genuine watches, including ornate antique pocket watches and beautifully crafted wrist watches. Working with well established and trusted dealers, you can shop with confidence from our collection. Dating back to the 19th century, browse ornate Victorian designs to post-40s military pieces - and everything in between. Our collection of special edition watches from famous household brands such as Cartier and Rolex, the perfect addition to your wrist.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/watches.jpg',
      'level': '2',
      'categoryId': '107',
      'parentId': '4'
    },
    {
      'title': 'Bracelets',
      'slug': 'bracelets',
      'description': '<p>Our collection of antique and contemporary bracelets are superior in design and craftsmanship. The perfect addition to an antique collection or gift for someone very special. Our collection includes eye-catching pieces from the Edwardian, Georgian, Victorian and Art Deco periods, featuring silver and gold bracelets with precious gems and stones. All of our bracelets are from well established dealers, you can browse and purchase with confidence in the knowledge that you are dealing directly with world renowned jewellery dealers and designers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '108',
      'parentId': '106'
    },
    {
      'title': 'Brooches',
      'slug': 'brooches',
      'description': '<p>Discover stunning antique and designer brooches from the country’s most recognised jewellery dealers. Our brooch collection features pieces from the 18th, 19th and 20th century, including pieces in silver, gold and platinum and decorated with diamonds, rubies and pearls. Our collection of one of a kind brooches can be purchased with confidence as you are dealing directly with dealers who are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '109',
      'parentId': '106'
    },
    {
      'title': 'Cufflinks',
      'slug': 'cufflinks',
      'description': '<p>Discover our collection of modern and antique cufflinks, all of which are carefully selected by world renowned jewellery dealers. Our collection includes cufflinks from the Victorian and Edwardian eras right up to the modern ear. Our cufflinks feature intricate patterns and spectacular use of precious gemstones and metals. Browse and purchase with confidence from our selection of experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '110',
      'parentId': '106'
    },
    {
      'title': 'Earrings',
      'slug': 'earrings',
      'description': '<p>Browse our selection of earrings, from gorgeous Georgian sets to bold mid-century masterpieces Discover timeless and elegant pieces in silver and gold, adorned with precious gems including emeralds and diamonds. All our items are sourced from expert dealers so you can shop with confidence.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '111',
      'parentId': '106'
    },
    {
      'title': 'Other Jewellery',
      'slug': 'other-jewellery',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/other-jewellery.jpg',
      'level': '3',
      'categoryId': '112',
      'parentId': '106'
    },
    {
      'title': 'Rings',
      'slug': 'rings',
      'description': '<p>Discover a collection of exquisite rings. Many of our antique rings are steeped in history and include detailing so intricate it is often difficult to replicate today. Our collection has unique pieces from the Georgian, Victorian and Edwardian eras in a variety of sizes and styles to suit all tastes and budgets. Browse and purchase with confidence from internationally recognised dealers who are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/rings.jpg',
      'level': '3',
      'categoryId': '113',
      'parentId': '106'
    },
    {
      'title': 'Pocket Watches',
      'slug': 'pocket-watches',
      'description': '<p>Discover our collection of antique pocket watches including hunter, half hunter, military and more. Dating from the 19th century onwards, our selection includes silver, gold and metal pieces, chains and keys. You’re sure to find the perfect piece for your collection from our trusted dealers, who are internationally recognised and experts in their fields.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '114',
      'parentId': '107'
    },
    {
      'title': 'Wrist Watches',
      'slug': 'wrist-watches',
      'description': '<p>Our selection of wrist watches are sourced by the country’s very best dealers who are experts in their field. Shop with confidence from our collection, which includes rare and unique antique time pieces from historic makers, periods and materials. Our collection of wrist watches includes one of a kind special edition timepieces from the 21st century, there’s something for every collector.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '115',
      'parentId': '107'
    },
    {
      'title': 'Ceramics',
      'slug': 'ceramics',
      'description': '<p>Discover our selection of ceramics from the incredibly rare to the simply beautiful. Included in our selection you can find everything from Porcelain, Prattware and Pearlware to Delftware and Slipware. You can shop with confidence as all of our ceramics come from internationally recognised dealers who are experts in their field. Find the perfect addition to your collection with pieces ranging from Medieval 15th century jugs and 19th century figures to stunning contemporary ceramics.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/ceramics.jpg',
      'level': '2',
      'categoryId': '117',
      'parentId': '5'
    },
    {
      'title': 'Glass',
      'slug': 'glass',
      'description': '<p>The history of glass dates back thousands of years and today the right piece of glass still offers the perfect balance between practical and beautiful, it can make the perfect gift or the perfect addition to your carefully curated collection. The exceptional collection of glass showcased here on 2Covet includes outstanding rare pieces, dating back as early as the 16th century and cutting edge contemporary glass design pieces to please even the most discerning eye. Alongside, our vetted and accredited dealers bring these wonderful pieces to you from some of the worlds best contemporary designers such as Higgins and Baccarat, simply beautiful Murano glass dishes and finely cut crystal tumblers to rare Venetian glass tazza’s and glass ewers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/glass.jpg',
      'level': '2',
      'categoryId': '118',
      'parentId': '5'
    },
    {
      'title': 'Metalwork',
      'slug': 'metalwork',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/metalware.jpg',
      'level': '2',
      'categoryId': '119',
      'parentId': '5'
    },
    {
      'title': 'Silver',
      'slug': 'silver',
      'description': '<p>Silver is conceivably one of the most valuable and versatile metals, a combination that makes the vast collection here on 2Covet attractive to almost any buyer. Silver has been used for both decorative and practical purposes since ancient times. Popular items of silver in this collection range from decorative antique centrepieces to complete flatware collections. Our silver category includes pieces from the flamboyant mid 18th Century rococo creations and the heavy ornamentation of Victorian design. Our silver includes pieces from the Renaissance period right up to modern stylists and movements such as designs from the Art Deco period with their easily distinguishable asymmetry and clean lines. Shop our collection of silver from the last 500 years and buy with confidence knowing you can deal directly with world renowned silver dealers many of whom reside within the world famous London Silver Vaults.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver.jpg',
      'level': '2',
      'categoryId': '116',
      'parentId': '5'
    },
    {
      'title': 'Animal Figural Silver',
      'slug': 'animal-figural-silver',
      'description': '<p>A decorative element of silver that can be both ornamental and practical is the use of silver in figural designs. Shop our collection of Animal and Figural Silver which includes intricately created animal designs, realistically portrayed in precious metal to combine nature and luxury, often an ode to the countryside lifestyle. Perfectly positioned in the home to add opulence and personality, these designs were created by some of the most talented silversmiths and now sold by the most esteemed antique dealers in the country. Figural silver creations can also include practical designs such as table bells and napkin rings. If you are looking for a statement piece, figural designs can also be seen in grandiose candlesticks and candelabra. This can add a touch of elegance to any table and creates a talking point at any dinner party. The versatility of figural silver designs means at 2Covet there will be a unique piece to suit any buyer.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '120',
      'parentId': '116'
    },
    {
      'title': 'Novelty Silver',
      'slug': 'novelty-silver',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '170',
      'parentId': '116',
      'level': '3'
    },
    {
      'title': 'Other Silver',
      'slug': 'other-silver',
      'description': '<p>The versatility of silver as a precious metal means that its uses are unending. Throughout history, silversmiths have found different methods of incorporating silver into a stunning selection of items which are showcased here in out ‘Other’ silver category. Whilst silver is commonly used in decoration and functional service, some other items include silver furniture, solid silver service ovals, silver urns, tazzas, scent bottles, mirrors and clocks. These pieces can truly be seen as a symbol of decadence and wealth, as the sheer weight, design and craftsmanship is simply extraordinary. From Edwardian, Victorian and Georgian eras you can really find a unique piece of silver here on 2Covet to add to your collection, knowing that the quality and calibre has been hand selected by one of our expert silver dealers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '125',
      'parentId': '116'
    },
    {
      'title': 'Silver Boxes',
      'slug': 'silver-boxes',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver-boxes.jpg',
      'categoryId': '169',
      'parentId': '116',
      'level': '3'
    },
    {
      'title': 'Silver Candlesticks',
      'slug': 'silver-candlesticks',
      'description': '<p>Our collection of Silver Candlesticks is largely dominated by antique silver as the candlestick has served a purpose since before 400BC. However, it has effortlessly transitioned into the modern day due to its timeless combination of functionality and luxury. Whilst providing light in early years, the candlestick was originally a symbol of wealth and opulence. We also have a selection of taper candlesticks, alter candlesticks and column candlesticks which were all silver designs that originally served a purpose at the time of their creation but are now fit to adorn any home to afford an element of warmth, functionality and elegance. The introduction of candelabra brought an added element of opulence during formal occasions. We also have many of multi-armed and typically more elaborate silver designs within our collection. Silver candlesticks are perfect to illuminate a room with light and grandeur. The range of candlesticks and candelabra here at 2Covet are part of collections by the best silver dealers in the country, many of whom reside in the World Famous London Silver Vaults, so rest assured you will find only items endorsed by the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver-candlesticks.jpg',
      'level': '3',
      'categoryId': '121',
      'parentId': '116'
    },
    {
      'title': 'Silver Centrepieces',
      'slug': 'silver-centrepieces',
      'description': '<p>Using silver in the centre of your table is the perfect way to add an impressive focal point to any dinner party, an idea that coincided with the trend of fine dining amongst the wealthy in France during the late 17th to early 18th century. However, the tradition of the centrepiece being included on tables since Greek and Roman eras, and the introduction of intricately crafted pieces of silver, with decorative, durable and reflective qualities, make them the perfect choice to adorn your home. The sheer range of options that silver presents, from vases and bowls, to jardinières and samovars means that whatever your budget and style, you can browse amongst a selection of Victorian, Edwardian, art nouveau and twentieth century items confident in finding the perfect piece. Silver centrepieces are also complementary to the other silver pieces on offer here at 2Covet. All of our silver is curated from the best silver dealers in the country, many of whom reside in the World Famous London Silver Vaults, so rest assured you will find only items endorsed by the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver-centrepieces.jpg',
      'level': '3',
      'categoryId': '122',
      'parentId': '116'
    },
    {
      'title': 'Silver Drinking Vessels',
      'slug': 'silver-drinking-vessels',
      'description': '<p>Our silver drinking vessels collection is ever growing and complements the vast range of silver pieces available at 2Covet. Whether you are looking for early silver, beginning with the Renaissance & Baroque periods or silver drinking vessels from the Edwardian, Georgian, Victorian or 20th century, you can find anything from tankards, mugs, tumblers, hip flasks, goblets, beakers, cups and chalices. Throughout history, whilst such items were widely available in materials such as wood and ceramics, silver pieces were widely reserved for the wealthy due to their high cost, at least until the price of silver dropped. The design of the wine glasses we drink from today are drawn from the silver pieces used in former years, due to their practical design and appealing style. Many of the silver drinking vessels available here on 2Covet include intricate designs, including floral decorations, lions, cherubs and faces, which would complement any dinner table, the complex history of such items is also a perfect talking point. Some vessels such as chalices and tankards are adorned with religious or mythological imagery, whilst other pieces are made across the world in countries such as America, Germany and Scandinavia.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '123',
      'parentId': '116'
    },
    {
      'title': 'Silver Flatware',
      'slug': 'silver-flatware',
      'description': '<p>Historically, silver flatware has shaped Western culture and it is hard to imagine a home without such items. Nowadays, flatware is easily accessible and affordable, but the luxury and elegance previously associated with owning these utensils and cutlery can still be found in the beautiful silver flatware found on 2Covet, expertly selected by our specialist silver dealers. Typically, silver flatware is used as part of a ‘working collection’ therefore, the non reactive properties of silver means that you can use silver flatware for service over and over again, without worrying about wearing down the items - a worthwhile investment piece. Whilst the knife, fork and spoon were invented separately during different periods in human history, it wasn’t until the industrial revolution that the beautiful silver flatware sets that we admire today found their origins. The theatre of using cutlery sets came to head during the Georgian period, through the Victorian era and into the present day, where this etiquette can still be brought into the modern home through the exquisite silver flatware selections available on 2Covet.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver-flatware.jpg',
      'level': '3',
      'categoryId': '124',
      'parentId': '116'
    },
    {
      'title': 'Silver Photos Frames',
      'slug': 'silver-photos-frames',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver-photoframe.jpg',
      'categoryId': '171',
      'parentId': '116',
      'level': '3'
    },
    {
      'title': 'Silver Tableware',
      'slug': 'silver-tableware',
      'description': '<p>Our selection of silver tableware available at 2Covet means you can add the final touches to your table and to your silver collection, knowing that all items have been skilfully selected by a world renowned silver dealer. Included in our tableware collections are; sugar casters, cruet sets, sauce boats, sugar bowls and tongs, biscuit baskets, port coasters, ice tongs, silver plates and vegetable dishes. Many of these pieces of silver tableware became increasingly popular alongside the rise of silver flatware. Other Items available in our silver tableware category are Items such as biscuit boxes, which rose in popularity as tea and coffee became widely available, with the silver adding a touch of luxury to afternoon tea. Silver cruet sets, including salt cellars, pepper pots and mustard pots, initially drew favour during the early 15th century and whilst salt shakers have become commonly used, these cruet sets are still firm collectors’ favourites. Meanwhile, during the 18th and 19th century as the consumption of goods became a way to prove your wealth and lifestyle, the rise in spirits and wines meant that silver edged coasters became an essential addition for the dining table so as to easily pass the wine and not damage the table. Nowadays such items are a perfect addition at a formal or even casual dinner party.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '126',
      'parentId': '116'
    },
    {
      'title': 'Silver Tea pots Tea sets',
      'slug': 'silver-tea-pots-tea-sets',
      'description': '<p>Discover our silver Teapot and Tea Sets Collection. Whilst the history of tea containers and teapots dates back to China in around 206BC, the European way of drinking tea that we know today took centuries longer to develop, and even so, we still see a significant Eastern influence in our tea drinking habits today. Due to the high cost of tea, the first silver teapots created were typically small and reserved for the wealthy. As tea drinking increased in popularity, so did the range of silver tea sets available, which during the early 1700s accessories such as sugar tongs, sugar boats and tea strainers were perfect additions to a tea drinkers collection. Today, these items would be a beautiful addition to an afternoon tea set or for hosting a tea party. Silver itself is the perfect material to retain the heat of the tea, and the thermal conductivity means the tea infuses at a higher temperature, allowing for the perfect blend. The durability and sturdy nature of the silvers means that the teapots can be used repeatedly and maintain quality, a great investment piece! Here at 2Covet, the selection of silver teapots and tea sets are selected by our expert silver dealers to ensure you find an unrivalled piece to add to your collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '127',
      'parentId': '116'
    },
    {
      'title': 'Wine Related Silver',
      'slug': 'wine-related-silver',
      'description': '<p>Whilst wine drinking rose in popularity during the 18th and 19th century, accessories such as wine funnels and labels played an important role in the decanting of wine. The selection here at 2Covet has been specially selected for practicality, purpose and design as our world famous dealers and collectors understand the link between functionality and craftsmanship. Our Silver Wine related category includes items which are both practical and beautiful, such as silver wine funnels, which are specially designed items to filter red wine of sediment, are still used today by wine connoisseurs and those who wish to ensure their wine is served to perfection. When decanted in glass bottles, silver labels can be used to display and differentiate between liquids. Decorative silver corkscrews are an added accessory that can provide a fun and functional element to your wine drinking experience. First patented in 1759, the initial design of corkscrew was so efficient it was used for over 100 years. The wine accessories here at 2Covet have been specially selected by our expert silver dealers, to ensure you get the perfect mix of design and purpose.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/silver-wine-related.jpg',
      'level': '3',
      'categoryId': '128',
      'parentId': '116'
    },
    {
      'title': 'Animal Figural Ceramics',
      'slug': 'animal-figural-ceramics',
      'description': '<p>We have an outstanding collection of ceramic animals and figures available, all of which are hand selected by our exceptional, expert dealers who offer a wealth of knowledge in their respective fields. Our animal and figural ceramics available on 2Covet include everything from rare and collectible 18th century figure groups to charming contemporary animal figures. Find the perfect addition to your ceramics collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '129',
      'parentId': '117'
    },
    {
      'title': 'Ceramic Bowls Dishes',
      'slug': 'ceramic-bowls-dishes',
      'description': '<p>Browse our selection of antique and contemporary ceramic bowls and dishes, shop with assurance from our widely accredited and vetted dealers. Our collection of ceramic bowls and dishes encompass both the simply beautiful to the intricate and includes everything from traditional English Georgian pieces to mid century decorative designs with striking use of colours. Shop 2Covet and deal directly with the experts.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/ceramics-bowls-dishes.jpg',
      'level': '3',
      'categoryId': '130',
      'parentId': '117'
    },
    {
      'title': 'Ceramic Tea Services and Teapots',
      'slug': 'ceramic-tea-services-and-teapots',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '132',
      'parentId': '117'
    },
    {
      'title': 'Decorative Ceramics',
      'slug': 'decorative-ceramics',
      'description': '<p>Our collection of decorative ceramics available on 2Covet are pieces that are aesthetically pleasing and some of which are hundreds of years old. Shop our selection of beautiful, decorative ceramics and add something superior to your ceramics collection. Our wonderful decorative ceramics could be the perfect finishing touch to that table setting and the perfect talking point at every dinner party. Our world-renowned dealers offer a range of beautiful, hand selected, decorative ceramic items including Japanese Pottery, Parisian busts and contemporary dishes.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/decorative-ceramics.jpg',
      'level': '3',
      'categoryId': '131',
      'parentId': '117'
    },
    {
      'title': 'Other Ceramics',
      'slug': 'other-ceramics',
      'description': '<p>Discover our selection of ceramics from the incredibly rare to the simply beautiful; ceramics have been used for thousands of years, with ancient ceramic uses recorded back to 8000bc, but ceramics continue to fulfil both practical and aesthetic uses today. Find the perfect addition to your collection with pieces ranging from Medieval 15th century jugs and 19th century figures to stunning contemporary ceramics. Included in our selection you can find everything from Porcelain, Prattware and Pearlware to Delftware and Slipware. You can shop with confidence as all of our ceramics come from internationally recognised dealers who are experts in their field.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/other-ceramics.jpg',
      'level': '3',
      'categoryId': '133',
      'parentId': '117'
    },
    {
      'title': 'Drinking Glasses',
      'slug': 'drinking-glasses',
      'description': '<p>View our collection of drinking glasses from our world-renowned accredited dealers. Our selection of drinking glasses offer something for even the most discerning tastes, including rare Venetian wine glasses dating back to the fifteen hundreds and finely cut crystal tumblers from the infamous Baccarat. Shop drinking glasses from the last 400 years here on 2Covet. The right piece of glass still offers the perfect balance between practical and beautiful, it can make the perfect gift or the perfect addition to your carefully curated collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '134',
      'parentId': '118'
    },
    {
      'title': 'Glass Decanters',
      'slug': 'glass-decanters',
      'description': '',
      'categoryId': '134.1',
      'parentId': '118',
      'level': '3'
    },
    {
      'title': 'Glass Tableware',
      'slug': 'glass-tableware',
      'description': '<p>Our ever growing collection of glass tableware features centrepieces from the Regency and Georgian periods as well as rare Venetian or Murano bowls and statement fused glass pieces from designers like Higgins. The right glass tableware can make your table-top truly memorable, choose from statement contemporary glass dishes or beautifully traditional cut glass goblets to create a tableware collection that is unique to you.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '3',
      'categoryId': '135',
      'parentId': '118'
    },
    {
      'title': 'Other Glass',
      'slug': 'other-glass',
      'description': '<p>The history of glass dates back thousands of years and today the right piece of glass still offers the perfect balance between practical and beautiful, it can make the perfect gift or the perfect addition to your carefully curated collection. The exceptional collection of glass showcased here on 2Covet includes outstanding, rare pieces dating back as early as the 16th century and cutting edge contemporary glass design pieces to please even the most discerning eye. Our vetted and accredited dealers bring these wonderful pieces to you from some of the worlds best contemporary designers such as Higgins and Baccarat alongside simply beautiful Murano glass dishes and finely cut crystal tumblers to rare Venetian glass tazza’s and glass ewers.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/other-glass.jpg',
      'level': '3',
      'categoryId': '136',
      'parentId': '118'
    },
    {
      'title': 'Aero Nautical',
      'slug': 'aero-nautical',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'level': '2',
      'parentId': '6',
      'categoryId': '174'
    },
    {
      'title': 'Books Maps and Globes',
      'slug': 'books-maps-and-globes',
      'description': '<p>View our collection of rare and collectible books and maps from accredited dealers on 2Covet. With years of specialist knowledge and a passion for their field, our range of books and maps includes a selection of antiquarian rare books, first editions and signed copies. Our specialist map and globe dealers offer outstanding vintage and antique maps and one of the finest antique globe collections in the world. Whether it’s to complete a personal collection or simply to purchase the ideal gift for someone special; our dealers have a wealth of knowledge and they’re happy to help you find that special item you’ve been looking for.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/books-maps-and-globes.jpg',
      'categoryId': '137',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Coins',
      'slug': 'coins',
      'description': '<p>View our collection of rare and antique coins here on 2Covet, all of our Coins are from well recognised and renowned experts, therefore you can buy with confidence knowing all of our dealers are the best in the business. Our collection features ancient historic and commemorative medallions, gold sovereigns, modern commemorative coins as well as rare British coins and rare foreign coins.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '138',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Fashion',
      'slug': 'fashion',
      'description': '<p>Immerse yourself in our beautiful, specialist collection of premium and vintage fashion and accessories from the world’s most coveted designers, such as Hermes, Chanel and Yves Saint Laurent. Our fashion dealers are renowned experts in their field and bring to you only the most exceptional items ranging from rare Victorian pieces to outstanding contemporary items.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/fashion.jpg',
      'categoryId': '139',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Luggage',
      'slug': 'luggage',
      'description': '<p>Our approved and recognised dealers specialise in fine luggage, including wonderful antique pieces dating as far back as the eighteen hundreds and vintage pieces from the best fashion houses such as Louis Vuitton, Hermes and Goyard. Our beautiful collection includes everything from small luggage items, such as cigar cases to large suitcases and trunks.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '140',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Maritime and Nautical',
      'slug': 'maritime-and-nautical',
      'description': '<p>2Covet offers a large range of Maritime and Nautical objects allowing you to traverse Britain\'s oceanic history through antiques. Featured in our Maritime and Nautical Collection are original pieces from some of the world’s best dealers and experts in all things nautical. Our dealers present an outstanding selection of both contemporary designs and maritime memorabilia and photography. Included here on 2Covet are also original antique model ships and highly rare and collectible nautical instruments. The perfect addition to any nautical themed room is the selection, lighting and décor on offer and If you are looking for something specific, our dealers can assist you with sourcing pieces from around the world.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/maritime-and-nautical.jpg',
      'categoryId': '141',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Militaria',
      'slug': 'militaria',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '146',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Scientific and Technical',
      'slug': 'scientific-and-technical',
      'description': '<p>Shop our collection of all things scientific and technical including medical instruments, compasses, telescopes, calculators and other collectables and curiosities. Our scientific and technical instruments range from the 17th century to rare contemporary instruments. You can shop confidently on 2Covet in the knowledge that all of the pieces we offer are presented to you by world renowned and well recognised dealers who are experts in their fields and have a true passion for what they do and helping people to build their personal collections or simply find the perfect gift.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '142',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Sporting',
      'slug': 'sporting',
      'description': '<p>We offer an outstanding collection of Sporting Antiques, Sports Memorabilia, Sporting Art, Equipment and Clothing here at 2Covet. All of our sporting related items are from specialist, accredited dealers. Explore our collection to find sports memorabilia which encompass football, rugby, fishing, hunting and shooting, motoring, aviation and all things equestrian. Browse our wide selection sporting equipment and clothing, including signed cricket bats, vintage hockey sticks and high quality antique tennis equipment. Our dealers also present wonderful sporting art pieces such as outstanding sailing photography, sports related editorials and stained glass.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '143',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Stamps',
      'slug': 'stamps',
      'description': '<p>Our established and approved stamp dealers offer a wide knowledge of their subject and a great passion for philately, our dealers are experienced at helping to create personal and exhibition level collections. Our excellent, high calibre stamp dealers enable us to offer you a collection of the finest and rarest examples of stamps from Britain and around the world.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '144',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Taxidermy',
      'slug': 'taxidermy',
      'description': '<p>By the late Victorian era, the interest in the natural world and game hunting meant that taxidermy had become a thriving industry. 2Covet and our widely knowledgeable, accredited dealers offer a selection of outstanding quality taxidermy from the most well known makers of taxidermy including Henry and Rowland Ward, James Hutchings and Thomas Gunn.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '145',
      'parentId': '6',
      'level': '2'
    },
    {
      'title': 'Globes',
      'slug': 'globes',
      'description': '<p>Navigate your way to something special on 2Covet with our collection of antique and modern globes. Our approved and accredited dealers offer you one of a kind antique globes which includes large terrestrial and celestial globes from famous globe-makers like Newton and Cary and small table globes perfect for a modern or antique desk. Our selection of globes includes everything from 18th century antique pieces, to a pair of striking contemporary library globes. If you can’t find what you’re looking for, our dealers are consistently sourcing globes from around the world and are happy to help you find that perfect, elusive addition to your collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/globes.jpg',
      'categoryId': '150',
      'parentId': '137',
      'level': '3'
    },
    {
      'title': 'Maps',
      'slug': 'maps',
      'description': '<p>Our established and approved map dealers offer a wide selection of original antique maps and modern, bespoke maps that you and your family can treasure for years to come. Our collection of maps includes pieces dating back to the 15th century and pieces that can be created to help you cherish your unique geographical memories. The collection of one of a kind maps on 2Covet is largely antique.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '149',
      'parentId': '137',
      'level': '3'
    },
    {
      'title': 'Maritime Memorabilia',
      'slug': 'Maritime memorabilia',
      'description': '<p>Browse our exceptional collection of maritime and nautical antiques and memorabilia, including incredibly rare, commemorative nautical and maritime items from the famous HMS Victory and items commemorating famous battles such as the Battle of Copenhagen as well as photography from artists such as Beken. Our collection includes small, commemorative items through to large ships canons and exceptional globes. Our dealers have years of knowledge so you can add exceptional pieces, such as ships canons and lights, to your collection with assurance.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/memorabilia.jpg',
      'categoryId': '151',
      'parentId': '141',
      'level': '3'
    },
    {
      'title': 'Nautical Instruments',
      'slug': 'nautical-instruments',
      'description': '<p>Our collection of Nautical Instruments on 2Covet includes binoculars, telescopes, compasses, gauges and instrument panels. All of our Nautical Instruments come from pre-approved, vetted dealers who offer a wealth of knowledge and experience in their field; if you’re looking for something specific, contact one of our dealers who can help you to find that piece you’ve been searching for.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '153',
      'parentId': '141',
      'level': '3'
    },
    {
      'title': 'Ship Models',
      'slug': 'ship-models',
      'description': '<p>Explore our range of wonderful Ship Models from our accredited, expert dealers. Our remarkable collection of ship models includes Victorian period half-hull models and large museum quality, glass cased scale models of famous ships such as the ‘Knight of Saint Michael’.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/ship-models.jpg',
      'categoryId': '152',
      'parentId': '141',
      'level': '3'
    },
    {
      'title': 'Medical',
      'slug': 'medical',
      'description': '<p>Discover our collection of medical and surgical objects. An unusual range of antique and rare medical instruments and memorabilia. Our collection includes surgical instruments and apothecary related items. All of our items come from accredited and vetted dealers who offer collections only of the best quality.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '154',
      'parentId': '142',
      'level': '3'
    },
    {
      'title': 'Telescopes and Binoculars',
      'slug': 'telescopes-and-binoculars',
      'description': '<p>Peruse our incredible collection of telescopes and binoculars including specialist military and marine items. Here on 2Covet we have an incredible range of antique telescopes and binoculars, from pieces dating back to World War II to impressive modern aircraft binoculars. Our pre-approved, expert dealers can offer advice and guidance on these special pieces, including wonderful rare items created by famous makers such as Negretti and Zambra.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '155',
      'parentId': '142',
      'level': '3'
    },
    {
      'title': 'Scientific Instruments',
      'slug': 'scientific-instruments',
      'description': '<p>Shop our range of antique and vintage scientific instruments from the best dealers in the world. Our collection includes everything from unique globes, barometers and telescopes to specialist nautical and medical instruments. Get in touch with one of our dealers who can help you identify the perfect pieces to add to your collection.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '156',
      'parentId': '142',
      'level': '3'
    },
    {
      'title': 'Sporting Art',
      'slug': 'sporting-art',
      'description': '<p>Discover our excellent selection of sporting art including paintings from famous artists such as Arthur Weaver and John Hassall and wonderful photography and editorial pieces from famous publications such as Vanity Fair. Our vetted and approved dealers bring you sporting art pieces covering everything from polo matches, golf and racing to hunting and athletics.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '159',
      'parentId': '143',
      'level': '3'
    },
    {
      'title': 'Sporting Equipment and Clothing',
      'slug': 'sporting-equipment-and-clothing',
      'description': '<p>View our range of antique and vintage sporting equipment and clothing from some of the worlds best sporting dealers. Find signed pieces and collectors items relating to a range of sports and sporting events across the globe. Our collection includes signed cricket bats from infamous cricket games of the 1950’s and rare football boots from years gone by. If you’re looking for something specific to complete your collection, contact one of our accredited dealers who will be happy to help.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '158',
      'parentId': '143',
      'level': '3'
    },
    {
      'title': 'Sports Memorabilia',
      'slug': 'sports-memorabilia',
      'description': '<p>Explore our collection of exceptional sports memorabilia from antique and vintage items to current highly sought after memorabilia and find the perfect addition to your collection or the perfect gift for the sports lover in your life. Here on 2Covet our Sporting Memorabilia collection has been curated by dealers who offer hundreds of years of experience and a wide selection of memorabilia from all sports, including the weird and wonderful. View our memorabilia to find pieces relating to your favourite sports including tennis, cricket, golf, football, winter sports and mountaineering.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '157',
      'parentId': '143',
      'level': '3'
    },
    {
      'title': 'Arms Armour and Weaponry',
      'slug': 'arms-armour-and-weaponry',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'description': '',
      'categoryId': '160',
      'parentId': '146',
      'level': '3'
    },
    {
      'title': 'Militaria Memorabilia',
      'slug': 'militaria-memorabilia',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '161',
      'parentId': '146',
      'level': '3'
    },
    {
      'title': 'Architectural Items',
      'slug': 'architectural-items',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '147',
      'parentId': '34',
      'level': '3'
    },
    {
      'title': 'Doors and Windows',
      'slug': 'doors-and-windows',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '165',
      'parentId': '34',
      'level': '3'
    },
    {
      'title': 'Fireplaces',
      'slug': 'fireplaces',
      'description': '<p>2Covet showcase an outstanding collection of fireplaces of the best quality from our world renowned 2Covet Dealers. Fireplaces were introduced in medieval times as a way to retain heat whilst directing smoke up the chimney and out of the house or castle, with early fireplaces widely made of stone and brick. Ever since the 15th century, fireplaces have maintained a decorative element, with a decorated slab of cast iron providing protection and adornment.</p><p>Practicality and finding ways to circulate heat more efficiently dominated progressive fireplace architecture up until the 18th and 19th century. At this point, the focus shifted to interior design, with craftsmen experimenting with shapes, materials and embellishment to provide a focal point in the home, often of gigantic proportions. Shop the spectacular selection of fireplaces available here on 2Covet.</p>',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/fireplaces.jpg',
      'categoryId': '163',
      'parentId': '34',
      'level': '3'
    },
    {
      'title': 'Fire Accessories',
      'slug': 'fire-accessories',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '164',
      'parentId': '34',
      'level': '3'
    },
    {
      'title': 'Fountains',
      'slug': 'fountains',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/fountains.jpg',
      'categoryId': '166',
      'parentId': '34',
      'level': '3'
    },
    {
      'title': 'Garden Furniture',
      'slug': 'garden-furniture',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/garden-furniture.jpg',
      'categoryId': '167',
      'parentId': '34',
      'level': '3'
    },
    {
      'title': 'Staircases',
      'slug': 'staircases',
      'description': '',
      'categoryImage': 'https://2covetimagearchive.s3.eu-west-2.amazonaws.com/category-images/generic.jpg',
      'categoryId': '168',
      'parentId': '34',
      'level': '3'
    }
  ];
