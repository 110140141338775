import { ApiResponse, ILapadaAdd, ILapadaAddRequest, IMediumAndMaterial, IMediumAndMaterialResponse } from '@models/index';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class LapadaService {

  constructor(
    private _httpClient: HttpClient,
    @Inject('LAPADA_ENDPOINT') private _endpoint: string
  ) { }

  public processLapadaForm(data: ILapadaAddRequest, token: string, cId: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('c-id', cId);
    return this._httpClient.post<void>(`${environment.API_URL}/integration-add`, data, {headers});
  }

  public processLapadaGet(data: { key: string, stock_number: string }, cId: string, token: string): Observable<ApiResponse<ILapadaAdd>> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.post<ApiResponse<ILapadaAdd>>(`${environment.API_URL}/integration-get-stock`, data, { headers });
  }

  public processLapadaUpdate(data: ILapadaAddRequest, token: string, cId: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('c-id', cId);
    headers = headers.append('token', token);
    return this._httpClient.put<void>(`${environment.API_URL}/integration-update/${data.stock_number}`, data, { headers });
  }

  public getCategories(data: IMediumAndMaterial, cId: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('c-id', cId);
    return this._httpClient.post<IMediumAndMaterialResponse>(`https://dev01.seekunique.co/api/integration-get-other-data`, data, {headers});
  }
}

